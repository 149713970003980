import React from "react";

const BetsGridTableHeader: React.FC = () => {
    return (
        <div className="flex h-10 items-center justify-between border-b border-gray-200 px-4 text-xs text-gray-500">
            <span>Bookie</span>
            <span>Bet Now</span>
        </div>
    );
};

export default BetsGridTableHeader;
