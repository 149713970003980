import * as React from "react";
import {SVGProps} from "react";
const SvgRefresh = (props: SVGProps<SVGSVGElement>) => (
    <svg fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            clipRule="evenodd"
            d="M3.2 1.6a.8.8 0 0 1 .8.8v1.681a5.602 5.602 0 0 1 9.281 2.052.8.8 0 0 1-1.508.534A4.002 4.002 0 0 0 4.8 5.6h2.4a.8.8 0 1 1 0 1.6h-4a.8.8 0 0 1-.8-.8v-4a.8.8 0 0 1 .8-.8zm.007 7.246a.8.8 0 0 1 1.02.487A4.002 4.002 0 0 0 11.2 10.4H8.8a.8.8 0 0 1 0-1.6h4a.8.8 0 0 1 .8.8v4a.8.8 0 1 1-1.6 0v-1.681a5.602 5.602 0 0 1-9.281-2.052.8.8 0 0 1 .488-1.021z"
            fillRule="evenodd"
        />
    </svg>
);
export default SvgRefresh;
