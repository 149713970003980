import * as React from "react";
import {SVGProps} from "react";
const SvgCheckMark = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 12 8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.366.234a.8.8 0 0 1 0 1.132l-6.4 6.4a.8.8 0 0 1-1.132 0l-3.2-3.2a.8.8 0 0 1 1.132-1.132L4.4 6.07 10.234.234a.8.8 0 0 1 1.132 0Z"
        />
    </svg>
);
export default SvgCheckMark;
