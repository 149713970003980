import React from "react";
/* eslint-disable import/no-unresolved */ //https://github.com/eslint/eslint/issues/15171
import {Swiper, SwiperSlide} from "swiper/react";
import type {OffersSliderProps} from "./OffersSlider.props";
import OfferTile from "../OfferTile/OfferTile";

const OffersSlider: React.FC<OffersSliderProps> = ({offers}) => (
    <Swiper
        slidesPerView={"auto"}
        autoHeight={true}
        spaceBetween={30}
        longSwipesRatio={0.2}
        threshold={3}
        scrollbar={true}
    >
        {offers.map((offer) => (
            <SwiperSlide key={offer.id}>
                <OfferTile {...offer} />
            </SwiperSlide>
        ))}
    </Swiper>
);

export default OffersSlider;
