import React from "react";
import clsx from "clsx";
import {useAnalytics} from "@bettorsignals/tracking";
import type {LinkTreeButtonProps} from "./LinkTreeButton.props";
import {useFramework} from "../internal/useFramework";

const LinkTreeButton: React.FC<LinkTreeButtonProps> = (props) => {
    const {href, name, Icon, classNames} = props;

    const {Link} = useFramework();
    const {event} = useAnalytics();

    return (
        <Link
            to={href}
            target="_blank"
            rel="noopener"
            className={clsx(
                "group relative flex w-full flex-1 justify-center rounded border p-2",
                classNames
            )}
            onClick={(): void =>
                event({
                    action: "Link Tree Button Click",
                    value: name
                })
            }
        >
            <div className="flex flex items-center">
                <div className="absolute left-2">{Icon}</div>
                <span className="text-lg font-medium">{name}</span>
            </div>
        </Link>
    );
};

export default LinkTreeButton;
