import * as React from "react";
import {SVGProps} from "react";
const SvgAmericanFootballSolid = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M19.887 12.792c-.375-2.778-1.614-5.33-3.49-7.17-1.84-1.84-4.393-3.078-7.171-3.491-2.59-.376-4.918.075-5.969 1.126-1.051 1.051-1.502 3.416-1.126 5.969.375 2.778 1.614 5.33 3.491 7.17 1.84 1.84 4.392 3.078 7.17 3.491.6.075 1.164.113 1.727.113 1.877 0 3.454-.45 4.242-1.276 1.014-1.014 1.464-3.341 1.126-5.932Z"
            fill="currentColor"
        />
        <path
            d="M19.887 12.792c-.375-2.778-1.614-5.33-3.49-7.17-1.84-1.84-4.393-3.078-7.171-3.491-2.59-.376-4.918.075-5.969 1.126-1.051 1.051-1.502 3.416-1.126 5.969.375 2.778 1.614 5.33 3.491 7.17 1.84 1.84 4.392 3.078 7.17 3.491.6.075 1.164.113 1.727.113 1.877 0 3.454-.45 4.242-1.276 1.014-1.014 1.464-3.341 1.126-5.932ZM3.782 3.82C4.458 3.144 5.847 2.77 7.5 2.77c.526 0 1.051.038 1.614.113.601.075 1.202.225 1.765.375a11.586 11.586 0 0 0-4.768 2.89 11.586 11.586 0 0 0-2.89 4.768c-.15-.563-.301-1.163-.376-1.764-.338-2.328.037-4.468.938-5.33Zm-.112 8.372a11.194 11.194 0 0 1 2.966-5.519 10.757 10.757 0 0 1 5.518-2.965 11.218 11.218 0 0 1 3.717 2.44 11.214 11.214 0 0 1 2.44 3.716 11.194 11.194 0 0 1-2.966 5.519 10.756 10.756 0 0 1-5.519 2.965 11.218 11.218 0 0 1-3.716-2.44 11.218 11.218 0 0 1-2.44-3.716Zm14.528 6.044c-.863.863-3.04 1.238-5.33.938-.601-.075-1.202-.225-1.765-.375a11.586 11.586 0 0 0 4.768-2.89 11.586 11.586 0 0 0 2.89-4.769c.15.564.3 1.164.376 1.765.337 2.327-.038 4.467-.939 5.33Z"
            fill="#fff"
        />
        <path
            d="m10.202 10.765 1.052 1.052-.489.488a.363.363 0 0 0 0 .525.406.406 0 0 0 .263.113.342.342 0 0 0 .263-.113l.488-.488 1.052 1.051-.489.489a.363.363 0 0 0 0 .525.406.406 0 0 0 .263.113.342.342 0 0 0 .263-.113l.488-.488.563.563a.405.405 0 0 0 .263.113.34.34 0 0 0 .263-.113.363.363 0 0 0 0-.525l-.563-.564.488-.488a.363.363 0 0 0 0-.525.363.363 0 0 0-.526 0l-.488.488-1.051-1.051.488-.489a.363.363 0 0 0 0-.525.363.363 0 0 0-.525 0l-.489.488-1.05-1.051.487-.488a.363.363 0 0 0 0-.526.363.363 0 0 0-.525 0l-.488.488L9.15 8.663l.488-.488a.363.363 0 0 0 0-.526.363.363 0 0 0-.525 0l-.488.488-.563-.563a.363.363 0 0 0-.526 0 .363.363 0 0 0 0 .526l.563.563-.526.488a.363.363 0 0 0 0 .526.406.406 0 0 0 .263.112.341.341 0 0 0 .263-.112l.488-.489 1.051 1.052-.488.488a.363.363 0 0 0 0 .525.405.405 0 0 0 .263.113.341.341 0 0 0 .263-.113l.525-.488Z"
            fill="#fff"
        />
        <path
            d="M19.887 12.792c-.375-2.778-1.614-5.33-3.49-7.17-1.84-1.84-4.393-3.078-7.171-3.491-2.59-.376-4.918.075-5.969 1.126-1.051 1.051-1.502 3.416-1.126 5.969.375 2.778 1.614 5.33 3.491 7.17 1.84 1.84 4.392 3.078 7.17 3.491.6.075 1.164.113 1.727.113 1.877 0 3.454-.45 4.242-1.276 1.014-1.014 1.464-3.341 1.126-5.932ZM3.782 3.82C4.458 3.144 5.847 2.77 7.5 2.77c.526 0 1.051.038 1.614.113.601.075 1.202.225 1.765.375a11.586 11.586 0 0 0-4.768 2.89 11.586 11.586 0 0 0-2.89 4.768c-.15-.563-.301-1.163-.376-1.764-.338-2.328.037-4.468.938-5.33Zm-.112 8.372a11.194 11.194 0 0 1 2.966-5.519 10.757 10.757 0 0 1 5.518-2.965 11.218 11.218 0 0 1 3.717 2.44 11.214 11.214 0 0 1 2.44 3.716 11.194 11.194 0 0 1-2.966 5.519 10.756 10.756 0 0 1-5.519 2.965 11.218 11.218 0 0 1-3.716-2.44 11.218 11.218 0 0 1-2.44-3.716Zm14.528 6.044c-.863.863-3.04 1.238-5.33.938-.601-.075-1.202-.225-1.765-.375a11.586 11.586 0 0 0 4.768-2.89 11.586 11.586 0 0 0 2.89-4.769c.15.564.3 1.164.376 1.765.337 2.327-.038 4.467-.939 5.33Z"
            fill="#fff"
        />
    </svg>
);
export default SvgAmericanFootballSolid;
