import React from "react";
import clsx from "clsx";
import {BettorSignals, CloseMark} from "@bettorsignals/assets";
import {NavBarProps} from "../NavBar.props";
import {useOnClickOutside} from "../NavBar.utils";
import MenuItem from "../MenuItem/MenuItem";
import {Banner} from "../../Banner";
import {useFramework} from "../../internal/useFramework";

type MobileMenuProps = NavBarProps & {setClose: () => void; isOpen: boolean};

const MobileMenu: React.FC<MobileMenuProps> = ({
    menuItems,
    contents,
    socialMediaIcons,
    setClose,
    isOpen
}) => {
    const {Link} = useFramework();

    const {ref} = useOnClickOutside({
        onOutsideClick: setClose
    });

    return (
        <div
            ref={ref}
            className={clsx(
                isOpen ? "translate-x-0" : "translate-x-full",
                "absolute inset-x-0 top-0 origin-top-right p-2 transition duration-500 md:hidden"
            )}
        >
            <div className="divide-y-2 divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="px-5 pt-5 pb-6">
                    <div className="flex items-center justify-between">
                        <div className="h-[30px] w-[90px]">
                            <Link to={"/"} onClick={setClose}>
                                <BettorSignals />
                            </Link>
                        </div>
                        <div className="-mr-2">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center bg-white p-2 text-gray-400"
                                onClick={setClose}
                            >
                                <span className="sr-only">Close menu</span>
                                <CloseMark className="h-4 w-4 text-bs-purple" />
                            </button>
                        </div>
                    </div>
                    <div className="mt-8">
                        <nav className="flex flex-col gap-8">
                            {menuItems.map((menuItem, index) => (
                                <MenuItem key={index} onClick={setClose} {...menuItem} />
                            ))}
                        </nav>
                    </div>
                </div>

                <div className="py-12 px-4 md:px-8 md:py-16">
                    <Banner contents={contents} socialMediaIcons={socialMediaIcons} />
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;
