import React, {Fragment} from "react";
import clsx from "clsx";
import {Menu, Transition} from "@headlessui/react";
import {StakeSelectorProps} from "./StakeSelector.props";
import {ChevronDownArrow} from "@bettorsignals/assets";
import {useConfig} from "../../internal/useConfig";

const StakeSelector: React.FC<StakeSelectorProps> = (props) => {
    const {values, onSelect, selectedStake} = props;
    const {currency} = useConfig();

    return (
        <Menu as="div" className="relative inline-block h-7 w-full text-left">
            <Menu.Button className="flex h-full w-full items-center justify-center rounded-md border border-gray-200 bg-white px-1 text-xs font-normal text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-bs-pink-light focus:ring-offset-2 focus:ring-offset-gray-100">
                <span>
                    {currency}
                    {selectedStake}
                </span>
                <span className="ml-1 hidden md:inline"> Stake</span>
                <ChevronDownArrow className="ml-1 h-1.5 w-2.5" aria-hidden="true" />
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-1 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {values.map((value, index) => (
                            <Menu.Item key={index}>
                                {({active}) => (
                                    <div
                                        className={clsx(
                                            "block cursor-pointer px-4 py-2 text-sm",
                                            active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                                        )}
                                        onClick={(): void => onSelect(value)}
                                    >
                                        {currency}
                                        {value}
                                    </div>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};
export default StakeSelector;
