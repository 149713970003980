import React from "react";
import copy from "copy-to-clipboard";
import {FacebookShareButton, TwitterShareButton, TelegramShareButton} from "react-share";
import {Facebook, Twitter, Telegram} from "@bettorsignals/assets";
import CopyButton from "./CopyButton/CopyButton";
import {ShareProps} from "../Share.props";

const ShareMenu: React.FC<ShareProps> = ({url}) => {
    return (
        <div className="absolute -inset-x-[214px] -inset-y-[60px] flex h-12 w-[250px] items-center gap-x-4 rounded bg-white p-3 shadow-lg">
            <CopyButton onClick={() => copy(url)} />

            <div className="flex items-center justify-center gap-x-3.5">
                <FacebookShareButton url={url}>
                    <Facebook className="h-8 w-8 fill-facebook-blue" />
                </FacebookShareButton>

                <TwitterShareButton url={url}>
                    <Twitter className="h-8 w-8 fill-twitter-blue" />
                </TwitterShareButton>

                <TelegramShareButton url={url}>
                    <Telegram className="h-8 w-8 fill-telegram-gradient" />
                </TelegramShareButton>
            </div>
        </div>
    );
};

export default ShareMenu;
