import React, {
    createContext,
    JSXElementConstructor,
    useMemo,
    DetailedHTMLProps,
    AnchorHTMLAttributes,
    Ref
} from "react";

export type To =
    | string
    | Partial<{
          pathname: string;
          search: string;
          hash: string;
      }>;

export type FrameworkAgnosticComponents = {
    useLocation: () => Pick<Location, "pathname" | "search" | "hash">;
    Link: JSXElementConstructor<
        DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
            ref?: Ref<HTMLAnchorElement>; //This way overriding = removing LegacyRef type
        } & {to: string | To}
    >;
};

export const FrameworkContext = createContext<FrameworkAgnosticComponents>(
    {} as FrameworkAgnosticComponents
);

const FrameworkProvider: React.FC<FrameworkAgnosticComponents> = (props) => {
    const {children, ...components} = props;
    const {Link, useLocation} = components;

    const frameworkContextValue = useMemo<FrameworkAgnosticComponents>(
        () => ({Link, useLocation}),
        [Link, useLocation]
    );

    return (
        <FrameworkContext.Provider value={frameworkContextValue}>
            {children}
        </FrameworkContext.Provider>
    );
};

export default FrameworkProvider;
