import React from "react";

const GoogleAnalyticsScript: React.FC<{measurementId: string | undefined}> = ({measurementId}) => {
    if (!measurementId) return null;

    return (
        <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`} />
            <script
                dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', '${measurementId}', {'send_page_view': false});`
                }}
            />
        </>
    );
};

export default GoogleAnalyticsScript;
