import React, {useCallback, useEffect, useState} from "react";
import {CookiesBannerProps} from "./CookiesBanner.props";
import {useFramework} from "../internal/useFramework";
import {CloseMark} from "@bettorsignals/assets";

const CookiesBanner: React.FC<CookiesBannerProps> = ({message, learnMoreButton, closeButton}) => {
    const [shouldRender, setShouldRender] = useState<boolean>(false);
    const {Link} = useFramework();

    const onBannerClose = useCallback(() => {
        window.localStorage.setItem("cookiePolicyAccepted", "true");
        setShouldRender(false);
    }, []);

    useEffect(() => {
        setShouldRender(!window.localStorage.getItem("cookiePolicyAccepted"));
    }, []);

    if (!shouldRender) return null;
    return (
        <div className="fixed inset-x-0 bottom-0 z-50 mx-auto w-screen bg-purple-50 p-2 md:p-3">
            <div className="flex h-full max-w-6xl flex-col items-stretch justify-center gap-4 md:mx-auto md:flex-row md:justify-between md:px-4">
                <div className="flex items-center gap-3">
                    <span aria-label="a cookie" role="img" className="text-2xl leading-6 md:p-2">
                        🍪
                    </span>
                    <span className="text-base font-normal leading-6 text-gray-600">{message}</span>
                </div>
                <div className="flex flex-row items-center justify-evenly gap-3 text-sm font-medium leading-5 md:gap-2 md:pl-4">
                    <Link
                        to={learnMoreButton.href}
                        className="flex h-10 w-full cursor-pointer items-center justify-center whitespace-nowrap rounded-md border border-bs-purple bg-white text-bs-purple outline-none md:px-4"
                    >
                        <span>{learnMoreButton.text}</span>
                    </Link>
                    <button
                        onClick={onBannerClose}
                        className="flex h-10 w-full cursor-pointer items-center justify-center rounded-md bg-bs-purple text-white outline-none"
                    >
                        <span className="block md:hidden">{closeButton.text}</span>
                        <span className="hidden md:flex md:w-10 md:justify-center">
                            <CloseMark className="h4 w-4 text-white" />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookiesBanner;
