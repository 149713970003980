import React, {Fragment, useMemo} from "react";
import {usePagination} from "./Pagination.utils";
import {PaginationProps} from "./Pagination.props";
import PaginationPageNumber from "./PaginationPageNumber";
import PaginationArrow from "./PaginationArrow";
import {useFramework} from "../internal/useFramework";

const Pagination: React.FC<PaginationProps> = ({currentPage, pagesCount, pagesToDisplay}) => {
    const {pageNumbers} = usePagination(currentPage, pagesCount, pagesToDisplay);
    const {useLocation} = useFramework();
    const {pathname, search} = useLocation();

    const currentSearchParameters = useMemo(
        () => Object.fromEntries(new URLSearchParams(search).entries()),
        [search]
    );

    return (
        <div className="flex items-center justify-between border-t border-gray-200 px-0">
            <div className="flex w-0 flex-1">
                <PaginationArrow
                    disabled={currentPage === 1}
                    to={{
                        pathname,
                        search: new URLSearchParams({
                            ...currentSearchParameters,
                            page: (currentPage - 1).toString()
                        }).toString()
                    }}
                    direction={"left"}
                    label="Previous"
                />
            </div>
            <div className="-mt-px flex">
                {pageNumbers.map((pageNumber) => (
                    <Fragment key={pageNumber}>
                        <PaginationPageNumber
                            isCurrentPage={currentPage === pageNumber}
                            to={{
                                pathname,
                                search: new URLSearchParams({
                                    ...currentSearchParameters,
                                    page: pageNumber.toString()
                                }).toString()
                            }}
                        >
                            {pageNumber}
                        </PaginationPageNumber>
                    </Fragment>
                ))}
            </div>
            <div className="flex w-0 flex-1 justify-end">
                <PaginationArrow
                    disabled={currentPage >= pagesCount}
                    to={{
                        pathname,
                        search: new URLSearchParams({
                            ...currentSearchParameters,
                            page: (currentPage + 1).toString()
                        }).toString()
                    }}
                    direction={"right"}
                    label="Next"
                />
            </div>
        </div>
    );
};

export default Pagination;
