import React from "react";
import {FooterProps} from "./Footer.props";
import {useFramework} from "../internal/useFramework";

const Footer: React.FC<FooterProps> = ({
    version,
    internalLinkGroups,
    externalLinks,
    social,
    heading,
    subHeading,
    domain
}) => {
    const {Link} = useFramework();

    return (
        <footer className="z-0 bg-gray-900" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="mx-auto max-w-bs px-4 pt-12 sm:px-6 lg:pt-16 xl:px-0">
                <div className="gap-8 md:grid md:grid-cols-2 xl:col-span-2 xl:mt-0">
                    <div className="grid grid-cols-2 gap-x-8 gap-y-12">
                        {internalLinkGroups.map(({label, links}, index) => (
                            <div key={index}>
                                <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">
                                    {label}
                                </h3>
                                <ul role="list" className="mt-4 space-y-4">
                                    {links.map(({name, href}) => (
                                        <li key={name}>
                                            <Link
                                                to={href}
                                                className="text-base text-gray-300 hover:text-gray-100"
                                            >
                                                {name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="mt-12 md:mt-0">
                        <div className="xl:col-span-1">
                            <div className="space-y-4">
                                <p className="text-sm font-semibold uppercase text-white">
                                    {heading}
                                </p>
                                <p className="text-base text-gray-300">{subHeading}</p>
                            </div>
                            <div className="mt-8 flex space-x-6">
                                {social.map(({name, href, Icon}) => (
                                    <Link
                                        key={name}
                                        to={href}
                                        className="flex h-6 w-6 items-center justify-center text-gray-300 hover:text-gray-100"
                                    >
                                        <span className="sr-only">{name}</span>
                                        <Icon
                                            className="h-6 w-6 fill-white hover:fill-bs-gradient-primary"
                                            aria-hidden="true"
                                        />
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-12 flex flex-col-reverse items-center justify-between gap-8 border-t border-gray-200 py-8 md:flex-row">
                    <div className="flex-shrink-0 text-center text-base text-gray-400 sm:text-left">
                        <p>
                            &copy; {new Date().getFullYear()} {domain}{" "}
                            <span title="Version">v{version}</span>
                        </p>
                        <p>All rights reserved. You must be 21+ years old to use this site.</p>
                    </div>
                    {externalLinks?.length && (
                        <div className="flex flex-col flex-wrap items-center gap-8 md:mb-0 md:grid-cols-2 md:flex-row md:justify-end md:gap-[42px]">
                            {externalLinks.map(({href, Icon}, index) => (
                                <Link
                                    key={index}
                                    to={href}
                                    target={"_blank"}
                                    referrerPolicy={"no-referrer"}
                                >
                                    <Icon />
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
