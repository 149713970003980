import React, {useCallback, useState} from "react";
import clsx from "clsx";
import type {WaitingBannerProps} from "./WaitingBanner.props";
import {BettorSignals} from "@bettorsignals/assets";
import {Banner} from "../Banner";

function getRandomNumberInRange(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

const WaitingBanner: React.FC<WaitingBannerProps> = (props) => {
    const {contents, withLogo = false, buttonText} = props;

    const [containerDimensions, setContainerDimensions] = useState<DOMRect | undefined>();
    const [buttonDimensions, setButtonDimensions] = useState<DOMRect | undefined>();
    const [topOffset, setTopOffset] = useState<number | undefined>();
    const [leftOffset, setLeftOffset] = useState<number | undefined>();

    const handleContainerDimensions = useCallback((node: HTMLDivElement | null) => {
        if (!node) return;
        setContainerDimensions(node.getBoundingClientRect());
    }, []);

    const handleButtonDimensions = useCallback((node: HTMLButtonElement | null) => {
        if (!node) return;
        setButtonDimensions(node.getBoundingClientRect());
    }, []);

    const handleButtonMove = useCallback(() => {
        if (!buttonDimensions || !containerDimensions) return;

        const relativeOffsetToContainerTop =
            containerDimensions.height - buttonDimensions.top + containerDimensions.top;

        const nextTopOffset = getRandomNumberInRange(
            (containerDimensions.height - relativeOffsetToContainerTop) * -1,
            containerDimensions.bottom - buttonDimensions.bottom
        );

        const nextLeftOffset = getRandomNumberInRange(
            buttonDimensions.width / 2,
            containerDimensions.width - buttonDimensions.width / 2
        );

        setTopOffset(nextTopOffset);
        setLeftOffset(nextLeftOffset);
    }, [buttonDimensions, containerDimensions]);

    return (
        <div
            ref={handleContainerDimensions}
            className={clsx("justify-inherit", "flex h-full flex-col items-center overflow-hidden")}
        >
            {withLogo && <BettorSignals className="mb-20 max-h-[88px] max-w-[250px] md:mb-24" />}

            <div className="max-w-xl">
                <Banner contents={contents} />
            </div>

            {buttonText && buttonText.length > 0 && (
                <div className="relative mt-12 w-full">
                    <button
                        style={{
                            top: topOffset,
                            left: leftOffset
                        }}
                        ref={handleButtonDimensions}
                        onPointerEnter={handleButtonMove}
                        onClick={handleButtonMove}
                        className="absolute top-0 left-2/4 -translate-x-2/4 whitespace-nowrap rounded-md bg-bs-pink px-6 py-2 text-base leading-6 text-white shadow-sm transition-all duration-150 ease-in-out"
                    >
                        {buttonText}
                    </button>
                </div>
            )}
        </div>
    );
};

export default WaitingBanner;
