import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {mergeRefs} from "react-merge-refs";
import {useFramework} from "../internal/useFramework";
import {MessageOfTheDayProps} from "./MessageOfTheDay.props";

const MessageOfTheDay: React.FC<MessageOfTheDayProps> = (props) => {
    const {message, href, speed = 20} = props;
    const {Link} = useFramework();
    const [animationDuration, setAnimationDuration] = useState<number>(0);
    const [messageRepeats, setMessageRepeats] = useState<number>(1);
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [marqueeWidth, setMarqueeWidth] = useState<number>(0);

    const messages = useMemo(
        () =>
            Array.from({
                length: messageRepeats || 1
            }).map((_, index) => (
                <Fragment key={index}>
                    <Link to={href} target="_blank" className="chromatic-ignore text-xs leading-4">
                        <span className="mx-[21px] font-light  text-white">{message}</span>
                    </Link>
                </Fragment>
            )),
        [messageRepeats, Link, href, message]
    );

    const handleContainerWidth = useCallback((node: HTMLDivElement | null) => {
        if (!node) return;
        setContainerWidth(node.getBoundingClientRect().width);
    }, []);

    const handleMarqueeWidth = useCallback((node: HTMLDivElement | null) => {
        if (!node) return;
        setMarqueeWidth(node.getBoundingClientRect().width);
    }, []);

    /**
     * Measures single message width and calculates how many times it can be repeated to fit.
     */
    const handleMarqueeMessageRepeats = useCallback(
        (node: HTMLDivElement) => {
            if (!node) return;
            const childNode = node.childNodes[0] instanceof Element && node.childNodes[0];
            if (!childNode) return;

            const singleMessageNodeWidth = childNode.getBoundingClientRect().width;
            const MAX_NUMBER_OF_REPEATS = 10;
            const numberOfMessagesThatCanFitContainer = Math.min(
                Math.max(Math.ceil(containerWidth / singleMessageNodeWidth), 1),
                MAX_NUMBER_OF_REPEATS
            );
            setMessageRepeats(numberOfMessagesThatCanFitContainer);
        },
        [containerWidth]
    );

    /**
     * Handles animation duration based on DOM measurements to ensure that the text moves at a constant speed, regardless of its length.
     */
    useEffect(() => {
        if (!marqueeWidth || !containerWidth) return;
        if (marqueeWidth > containerWidth) {
            setAnimationDuration(marqueeWidth / speed);
        } else {
            setAnimationDuration(containerWidth / speed);
        }
    }, [containerWidth, marqueeWidth, speed]);

    return (
        <div
            ref={handleContainerWidth}
            className="relative flex h-[38px] w-screen flex-row overflow-x-hidden bg-bs-purple"
        >
            <div
                ref={mergeRefs([handleMarqueeWidth, handleMarqueeMessageRepeats])}
                style={{animationDuration: `${animationDuration}s`}}
                className="z-10 flex min-w-full flex-shrink-0 flex-grow-0 basis-auto animate-marquee flex-row items-center"
            >
                {messages}
                <span className="sr-only">{message}</span>
            </div>
            {/**
             * Section and its elements are copies of the marquee above to ensure smoothness and look like the messages are repeating.
             * Using only one marquee section would trail the last element until it gets to the edge of the screen and then jumps back to the first item.
             */}
            <div
                style={{animationDuration: `${animationDuration}s`}}
                className="z-10 flex min-w-full flex-shrink-0 flex-grow-0 basis-auto animate-marquee flex-row items-center"
                aria-hidden="true"
            >
                {messages}
            </div>
        </div>
    );
};

export default MessageOfTheDay;
