import React from "react";
import {JumbotronProps} from "./Jumbotron.props";

const Jumbotron: React.FC<JumbotronProps> = ({heading, subheading}) => {
    return (
        <div className="flex h-[220px] flex-col justify-center bg-gray-50 py-16 px-4 sm:h-80">
            <div className="mx-auto w-full max-w-bs">
                <h1 className="text-4xl font-extrabold text-gray-900 sm:text-6xl">{heading}</h1>
                <p className="my-6 text-xl font-normal text-gray-600">{subheading}</p>
            </div>
        </div>
    );
};

export default Jumbotron;
