import {RefObject, useCallback, useEffect, useRef, useState} from "react";

interface UseOnClickOutsideProps {
    onOutsideClick: () => void;
}

export function useOnClickOutside({onOutsideClick}: UseOnClickOutsideProps): {
    ref: RefObject<HTMLDivElement>;
} {
    const ref = useRef<HTMLDivElement>(null);

    const eventHandler = useCallback(
        (event: Event) => {
            const element = ref?.current;

            // Do nothing if clicking ref's element or descendent elements
            if (!element || element.contains(event.target as Node)) return;

            onOutsideClick();
        },
        [onOutsideClick, ref]
    );

    useEffect(() => {
        window.addEventListener("mousedown", eventHandler);

        return () => window.removeEventListener("mousedown", eventHandler);
    }, [eventHandler, ref]);

    return {ref};
}

/**
 * Debounce util forces a function to wait a certain amount of time before running again.
 * @param {() => void} callback Function which should be wrapped in debounce
 * @param {number} waitMs This will allow to set a limit milliseconds on how often callback function can fire
 */
export function debounce(callback: (...args: unknown[]) => void, waitMs: number): EventListener {
    let timeout: ReturnType<typeof setTimeout>;

    return (...args: unknown[]): void => {
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(window, args), waitMs);
    };
}

interface UseHeadroomProps {
    threshold?: number;
}

export function useHeadroom({threshold = 0}: UseHeadroomProps): {isPinned: boolean} {
    const [scrollOffset, setScrollOffset] = useState<number>(0);
    const lastScrollOffsetRef = useRef<number>(scrollOffset);

    const handleScroll = useCallback(() => setScrollOffset(window.scrollY), []);

    // Tracks scroll values with event listener
    useEffect(() => {
        const debouncedHandleScroll = debounce(handleScroll, 30);

        window.addEventListener("scroll", debouncedHandleScroll);

        return () => window.removeEventListener("scroll", debouncedHandleScroll);
    }, [handleScroll]);

    // Updates the value of the last scroll offset
    useEffect(() => {
        lastScrollOffsetRef.current = scrollOffset;
    }, [scrollOffset]);

    return {
        isPinned: lastScrollOffsetRef.current >= scrollOffset || scrollOffset <= threshold
    };
}
