import {OddsFormat} from "../consts";
// @ts-ignore - types are missing
import * as oddsLib from "oddslib"; //https://github.com/1player/oddslib

//Maps to oddslib package
const oddsFormatDictionary = {
    [OddsFormat.Decimal]: "decimal",
    [OddsFormat.Fractional]: "fractional",
    [OddsFormat.American]: "moneyline",
    [OddsFormat.HongKong]: "hongKong",
    [OddsFormat.Malaysian]: "malay",
    [OddsFormat.Indonesian]: "indonesian"
};

export function formatOdds(decimalOdds: number, to: OddsFormat): string {
    const isToAmerican = to === OddsFormat.American;

    const convertedOdds: string | number = oddsLib
        .from("decimal", decimalOdds)
        .to(oddsFormatDictionary[to], {precision: isToAmerican ? 0 : 2}); //Precision makes sense for decimals and fractionals @see https://github.com/1player/oddslib#readme

    //oddslib returns american odds as number, while we need it as a string with a sign
    if (isToAmerican && convertedOdds > 0) {
        return "+" + convertedOdds;
    }

    return convertedOdds.toString();
}
