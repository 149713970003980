import React, {Fragment, useState} from "react";
import clsx from "clsx";
import {formatDistanceStrict} from "date-fns";
import {Avatar} from "../Avatar";
import {Rating} from "../Rating";
import {Share} from "../Share";
import {BetsGrid} from "../BetsGrid";
import type {SignalProps} from "./Signal.props";
import {useFramework} from "../internal/useFramework";

const SEE_MORE_CHARACTERS_THRESHOLD = 300;

const Signal: React.FC<SignalProps> = (props) => {
    const {
        isActive = true,
        categories,
        author,
        datePublished,
        dateExpires,
        confidence,
        title,
        content,
        shareUrl,
        disableShowMore = false,
        odds,
        bookmakers,
        trackingContext,
        onClick,
        href
    } = props;
    const isShowMoreNeeded: boolean = !!(
        !disableShowMore &&
        content &&
        content.length > SEE_MORE_CHARACTERS_THRESHOLD
    );
    const [isShowingMore, setIsShowingMore] = useState<boolean>(false);

    const {Link} = useFramework();

    return (
        <article
            data-testid="signal"
            className={clsx(
                !isActive && "opacity-60",
                // signal-article classes are defined in packages/ui/src/global.css
                "signal-article relative bg-white",
                onClick && "cursor-pointer hover:bg-gray-50"
            )}
            onClick={(event) => {
                const isTextSelected = window?.getSelection()?.toString()?.length;
                const target = event?.target as HTMLElement | undefined;

                if (target?.tagName === "ARTICLE" && !!onClick && !isTextSelected) {
                    onClick();
                }
            }}
        >
            {isActive && (
                <div className="flex h-6 w-full items-center bg-green-600">
                    <span className="pl-[13px] text-xs font-medium text-white">
                        <span className="mr-1" aria-label="a race flag emoji" role="img">
                            🏁
                        </span>
                        <span className="chromatic-ignore">
                            Starts{" "}
                            {formatDistanceStrict(new Date(dateExpires), new Date(Date.now()), {
                                addSuffix: true
                            })}
                        </span>
                    </span>
                </div>
            )}

            <div className={"border border-gray-200"}>
                <div className="flex items-center gap-x-1.5 border-b border-gray-200 py-2 px-3 font-medium leading-4 text-gray-900">
                    {categories.map(({name, Icon}, index) => {
                        const isLastCategory = index === categories.length - 1;
                        return (
                            <Fragment key={index}>
                                {React.cloneElement(Icon, {height: "16px", width: "16px"})}
                                <span className="text-xs font-medium text-gray-900">{name}</span>
                                {!isLastCategory && <span className="text-gray-500">・</span>}
                            </Fragment>
                        );
                    })}
                </div>

                <div className="flex flex-row gap-3 px-3 pt-4">
                    <div className="inline-block">
                        <Avatar nickname={author.nickname} src={author.avatarSrc} />
                    </div>
                    <div className="flex w-full flex-col gap-2">
                        <div className="flex flex-wrap">
                            <div className="flex flex-1 flex-wrap items-center whitespace-nowrap">
                                <span className="pointer-events-none mr-2 text-base font-semibold text-gray-900">
                                    {author.nickname}
                                </span>
                                <span className="chromatic-ignore pointer-events-none mr-2 text-sm font-medium text-gray-500">
                                    {formatDistanceStrict(
                                        new Date(datePublished),
                                        new Date(Date.now()),
                                        {
                                            addSuffix: true
                                        }
                                    )}
                                </span>
                            </div>
                            <div className="mt-[5px] flex justify-end">
                                <Rating value={confidence} />
                            </div>
                        </div>
                        <div className="text-base font-semibold text-gray-800">
                            {href ? (
                                <Link className="hover:underline" to={href}>
                                    {title}
                                </Link>
                            ) : (
                                <h1>{title}</h1>
                            )}
                        </div>
                        <div className="whitespace-pre-line text-sm font-normal text-gray-800">
                            <span>
                                {isShowMoreNeeded
                                    ? content!.slice(0, SEE_MORE_CHARACTERS_THRESHOLD)
                                    : content}
                            </span>
                            {isShowMoreNeeded && (
                                <>
                                    <button
                                        aria-hidden={isShowingMore}
                                        onClick={() => setIsShowingMore(true)}
                                        className={clsx(
                                            isShowingMore ? "hidden" : "text-bs-purple"
                                        )}
                                    >
                                        {" "}
                                        ...see more
                                    </button>
                                    <span
                                        className={clsx(
                                            isShowingMore
                                                ? "opacity-100 transition-opacity duration-200 ease-in"
                                                : "sr-only opacity-0"
                                        )}
                                    >
                                        {content!.slice(SEE_MORE_CHARACTERS_THRESHOLD)}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="w-full px-3 pb-4 sm:ml-auto sm:w-[calc(100%-60px)]">
                    <div className="my-4 flex flex-col gap-4">
                        <BetsGrid
                            odds={odds}
                            bookmakers={bookmakers}
                            trackingContext={trackingContext}
                        />

                        <p className="pointer-events-none text-[9px] font-normal italic leading-3">
                            The odds are current at the time of writing this signal! Refer to the
                            bookmaker&apos;s website for the live odds.
                        </p>
                    </div>
                    <div className="flex flex-1 justify-end gap-3 border-t border-gray-200 pt-[6px]">
                        <Share url={shareUrl} />
                    </div>
                </div>
            </div>
        </article>
    );
};

export default Signal;
