import * as React from "react";
import {SVGProps} from "react";
const SvgBasketballSolid = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx={11} cy={11} r={9} fill="currentColor" />
        <path
            d="M11.01 20h-.001a9.017 9.017 0 0 1-2.138-.258 8.938 8.938 0 0 1-5.558-4.067A8.938 8.938 0 0 1 2.26 8.869 8.973 8.973 0 0 1 10.995 2c.717 0 1.436.087 2.139.258 4.818 1.176 7.784 6.054 6.61 10.874A8.973 8.973 0 0 1 11.009 20Zm-.015-17.158a8.134 8.134 0 0 0-7.919 6.227 8.103 8.103 0 0 0 .955 6.17 8.103 8.103 0 0 0 5.038 3.685 8.133 8.133 0 0 0 9.856-5.992c1.064-4.368-1.624-8.79-5.992-9.855a8.17 8.17 0 0 0-1.939-.235Z"
            fill="#fff"
        />
        <path
            d="m15.75 18.44-.839-.073C15.78 8.242 6.533 4.062 6.44 4.021l.338-.771c.1.043 9.89 4.47 8.972 15.19Z"
            fill="#fff"
        />
        <path
            d="m2.885 10.001-.753-.375C3.106 7.666 5.187 7.79 7.2 7.908c1.566.093 3.19.188 4.358-.649 2.488-1.782.842-4.338.77-4.446l.702-.463c.759 1.148 1.53 3.794-.983 5.593-1.41 1.01-3.262.901-4.897.805-1.9-.113-3.537-.208-4.265 1.253ZM7.721 19.314l-.128-.832c1.705-.264 2.646-1.861 3.644-3.551.82-1.39 1.667-2.828 3.002-3.474 2.427-1.179 4.57.532 5.366 1.6l-.675.503c-.077-.103-1.921-2.511-4.323-1.347-1.107.537-1.855 1.804-2.645 3.145-1.053 1.785-2.142 3.63-4.24 3.955ZM3.976 15.937l-.447-.713 14.538-9.097.447.713-14.538 9.097Z"
            fill="#fff"
        />
        <circle cx={11} cy={11} r={8.5} stroke="currentColor" />
    </svg>
);
export default SvgBasketballSolid;
