import {useMemo} from "react";

/**
 * Returns a method that creates an array of sequential page numbers depending on the current page.
 * @param currentPage Sets the current page number, is crucial for calculation.
 * @param pagesCount Sets the number of available pages that the user can navigate through.
 * @param pagesToDisplay Sets the range of page numbers to display. Default value: 5.
 */
export const usePagination = (
    currentPage: number,
    pagesCount: number,
    pagesToDisplay: number = 5
) => {
    const pageNumbers = useMemo(() => {
        const currentPageIndex = currentPage - 1; //Since our props are not index-based
        const pivotIndex = Math.floor(pagesToDisplay / 2); //determining what is the mid-point of the desired array (desired = consisting of pagesToDisplay)

        const isBeforePivot = currentPageIndex <= pivotIndex; //condition that prevents shifting the numbers before we hit the pivot index

        const maximumShiftStart = pagesCount - pagesToDisplay; // determines the maximum shifting start value; this is the difference between the amount of pages and the amount of numbers to be displayed
        const start = Math.max(
            0,
            Math.min(isBeforePivot ? 0 : currentPageIndex - pivotIndex, maximumShiftStart)
        ); //if we are before the pivot we are not shifting the array; else we shift; also we ensure that shifting start index never gonna be negative or bigger then difference between the amount of pages and the amount of page numbers we display

        const end = pagesToDisplay + start; //shifting but always showing the desired number of numbers

        return Array.from({length: pagesCount})
            .map((_, index) => index + 1)
            .slice(start, end);
    }, [currentPage, pagesCount, pagesToDisplay]);

    return {pageNumbers};
};
