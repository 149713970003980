import React, {createContext} from "react";
import {OddsFormat} from "../consts";

export interface ConfigContextValue {
    currency: string;
    oddsFormat: OddsFormat;
}

export const ConfigContext = createContext<ConfigContextValue>({} as ConfigContextValue);

const ConfigContextProvider: React.FC<ConfigContextValue> = ({children, ...props}) => {
    return <ConfigContext.Provider value={props}>{children}</ConfigContext.Provider>;
};

export default ConfigContextProvider;
