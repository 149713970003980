import React from "react";
import clsx from "clsx";
import {ChevronDownArrow} from "@bettorsignals/assets";
import {Disclosure, Transition} from "@headlessui/react";
import {useFramework} from "../internal/useFramework";
import {TableOfContentsProps} from "./TableOfContents.props";

const TableOfContents: React.FC<TableOfContentsProps> = ({tableOfContents}) => {
    const {Link} = useFramework();

    return (
        <Disclosure defaultOpen aria-label="Table of contents" className="px-6" as="nav">
            {({open}) => (
                <>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left">
                        <span className="text-xl font-bold leading-7 text-gray-900">
                            Table of Contents
                        </span>
                        <span className="flex h-7 items-center">
                            <ChevronDownArrow
                                className={clsx(
                                    open ? "-rotate-180" : "rotate-0",
                                    "h-6 w-6 transform p-[5px] text-gray-400"
                                )}
                                aria-hidden="true"
                            />
                        </span>
                    </Disclosure.Button>

                    <Transition
                        show={open}
                        enter="transition duration-150 ease-in"
                        enterFrom="transform -translate-y-10 opacity-0"
                        enterTo="transform translate-y-0 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform translate-y-0 opacity-100"
                        leaveTo="transform -translate-y-10 opacity-0"
                    >
                        <Disclosure.Panel
                            as="ol"
                            className={clsx(open ? "block" : "hidden", "mt-3 ml-6 list-decimal")}
                            static
                        >
                            {tableOfContents.map((heading) => (
                                <li
                                    key={heading.id}
                                    style={heading.order >= 3 ? {marginLeft: "1em"} : {}}
                                    className="mb-3 text-lg font-medium leading-7 text-gray-900 last:mb-0"
                                >
                                    <Link
                                        className="translate-y-full transition-all duration-100 ease-in-out hover:text-bs-purple hover:underline"
                                        to={heading.href}
                                    >
                                        {heading.title}
                                    </Link>
                                </li>
                            ))}
                        </Disclosure.Panel>
                    </Transition>
                </>
            )}
        </Disclosure>
    );
};

export default TableOfContents;
