import React, {Fragment} from "react";
import {Popover, Transition} from "@headlessui/react";
import {Share as ShareIcon} from "@bettorsignals/assets";
import ShareMenu from "./ShareMenu/ShareMenu";
import {ShareProps} from "./Share.props";
import clsx from "clsx";

const Share: React.FC<ShareProps> = (props) => {
    return (
        <Popover className={"relative"}>
            {({open}) => (
                <>
                    <Transition
                        as={Fragment}
                        show={open}
                        enter="transition duration-100 ease-out"
                        enterFrom="transform translate-y-2 opacity-0"
                        enterTo="transform translate-y-0 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform translate-y-0 opacity-100"
                        leaveTo="transform translate-y-2 opacity-0"
                    >
                        <Popover.Panel>
                            <ShareMenu {...props} />
                        </Popover.Panel>
                    </Transition>
                    <Popover.Button
                        aria-label={"Share"}
                        className={clsx(
                            "flex h-[30px] w-9 items-center justify-center rounded-[20px]",
                            "border border-gray-200 bg-white outline-0 transition hover:bg-gray-50"
                        )}
                    >
                        <ShareIcon className="h-3 w-3 text-gray-400" />
                    </Popover.Button>
                </>
            )}
        </Popover>
    );
};

export default Share;
