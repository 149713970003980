import * as React from "react";
import {SVGProps} from "react";
const SvgFlameOutline = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12.026 4.228c.096 1.357.671 3.087 2.462 4.782l-.015.02.366.299a5.727 5.727 0 1 1-8.843 2.112 4.98 4.98 0 0 0 .262.66l.037.08c.162.37.433.63.767.745a1.224 1.224 0 0 0 1.56-.816c.1-.342.051-.72-.145-1.086-1.014-1.887-.877-3.66-.021-5.136.736-1.27 2.023-2.347 3.634-3.064-.064.384-.103.86-.064 1.404Z"
            stroke="currentColor"
        />
    </svg>
);
export default SvgFlameOutline;
