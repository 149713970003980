import React from "react";
import {ArrowLongRight} from "@bettorsignals/assets";
import {useFramework} from "../../internal/useFramework";
import type {NewsItemProps} from "./NewsItem.props";

const NewsItem: React.FC<NewsItemProps> = ({
    title,
    sneakPeek,
    cta,
    href,
    imageUrl,
    containerWidth
}) => {
    const {Link} = useFramework();

    return (
        <Link to={href} className="group">
            <div style={{width: containerWidth}} className="relative h-48 md:h-[238px]">
                <img
                    loading="eager"
                    className="absolute -z-20 h-full w-full bg-black object-cover"
                    src={imageUrl}
                    alt={`${title} image`}
                />
                <div className="absolute -z-10 h-full w-full bg-black opacity-60 transition-opacity duration-500 ease-in-out group-hover:opacity-40" />
                <div className="flex h-full w-full flex-col justify-end gap-2.5 px-4 pb-[30px] pt-4 md:px-[30px]">
                    <span className="text-2xl font-extrabold leading-8 text-white line-clamp-2 text-shadow-bs-black md:mb-1">
                        {title}
                    </span>

                    {sneakPeek && (
                        <span className="text-sm font-normal leading-5 text-white line-clamp-2 text-shadow-bs-black">
                            {sneakPeek}
                        </span>
                    )}

                    <div className="flex flex-row items-center gap-3.5 text-sm font-bold text-bs-pink">
                        <span>{cta}</span>
                        <span className="transition-transform duration-200 ease-in-out group-hover:translate-x-2">
                            <ArrowLongRight className="h-5 w-5" />
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default NewsItem;
