import * as React from "react";
import {SVGProps} from "react";
const SvgHamburger = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 20 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M2 2h16M2 8h16M2 14h16"
            stroke="currentColor"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default SvgHamburger;
