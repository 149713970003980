import {useEffect, useState, useCallback} from "react";

export const useScrollOffset = (offset = 300): {isPastOffset: boolean} => {
    const [isPastOffset, setIsPastOffset] = useState(false);

    const handleScroll = useCallback(() => {
        if (window.scrollY > offset) {
            setIsPastOffset(true);
        } else {
            setIsPastOffset(false);
        }
    }, [offset]);

    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    return {isPastOffset};
};
