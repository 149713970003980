import React, {Fragment} from "react";
import {BreadcrumbsProps} from "./Breadcrumbs.props";
import {useFramework} from "../internal/useFramework";

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({pages}) => {
    const {Link} = useFramework();
    return (
        <div className="flex items-center gap-x-5">
            {pages.map(({href, name}, index) => {
                const isLastPage = index === pages.length - 1;
                return (
                    <Fragment key={index}>
                        <div className="text-sm font-medium capitalize text-gray-500">
                            <Link to={href}>{name}</Link>
                        </div>
                        {!isLastPage && <span className="text-gray-300">/</span>}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default Breadcrumbs;
