import React from "react";
import {useFramework} from "../internal/useFramework";
import {SocialMediaIconProps} from "./SocialMediaIcon.props";

const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({Icon, url}) => {
    const {Link} = useFramework();

    return (
        <Link
            to={url}
            target={"_blank"}
            className="flex h-6 w-6 items-center justify-center md:h-5 md:w-5"
            rel="noreferrer"
        >
            <Icon className="h-full w-full hover:fill-bs-gradient-primary" />
        </Link>
    );
};

export default SocialMediaIcon;
