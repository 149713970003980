import * as React from "react";
import {SVGProps} from "react";
const SvgCloseMark = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 16 16" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M2 14 14 2M2 2l12 12"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default SvgCloseMark;
