import React from "react";
import type {BetsGridCalculatorType} from "./BetsGridCalculator.props";
import StakeSelector from "../StakeSelector";
import {useConfig} from "../../internal/useConfig";
import {formatOdds} from "../../internal/formatOdds";

const BetsGridCalculator: React.FC<BetsGridCalculatorType> = (props) => {
    const {onSelect, values, selectedStake, odds} = props;
    const {currency, oddsFormat} = useConfig();

    return (
        <div className="mb-2.5 inline-grid h-12 w-full grid-cols-5 grid-rows-1 items-center rounded border border-gray-200 bg-white px-4">
            <div className="col-span-2 justify-self-start">
                <h2 className="text-sm font-semibold leading-5 text-gray-800">
                    Odds: {formatOdds(odds, oddsFormat)}
                </h2>
            </div>

            <div className="col-span-2 justify-self-end pr-5 sm:pr-0">
                <div className="max-w-[84px]">
                    <StakeSelector
                        onSelect={onSelect}
                        values={values}
                        selectedStake={selectedStake}
                    />
                </div>
            </div>

            <div className="col-span-1 justify-self-end">
                <h2 className="text-base font-semibold leading-4 text-bs-gradient-primary">
                    {currency}
                    {(Number(odds) * selectedStake).toFixed(2)}
                </h2>
                <p className="text-center text-[8px] leading-[8px] text-gray-400">approx.</p>
            </div>
        </div>
    );
};

export default BetsGridCalculator;
