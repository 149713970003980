import React from "react";
import clsx from "clsx";
import {SmartlinkRowProps} from "./SmartlinkRow.props";
import {useAnalytics} from "@bettorsignals/tracking";
import {useFramework} from "../../../internal/useFramework";

const SmartlinkRow: React.FC<SmartlinkRowProps> = ({trackingContext}) => {
    const {Link} = useFramework();
    const {event} = useAnalytics();

    return (
        <div className="flex h-[60px] items-center justify-end bg-pink-100 px-4">
            <Link
                to="https://maxbon.us/bettorsignals"
                target="_blank"
                rel="noreferrer"
                className={clsx(
                    "flex h-[34px] w-[80px] bg-size-200 bg-position-0 hover:bg-position-100",
                    "items-center justify-center gap-2 whitespace-nowrap rounded-md bg-gradient-to-r from-bs-pink via-bs-purple to-bs-pink py-[9px] px-[10.5px]",
                    "text-xs font-medium leading-4 text-white transition-all duration-500 ease-in-out lg:text-sm"
                )}
                onClick={(): void => {
                    event({
                        action: "Bet Button Click",
                        label: "Best Offer",
                        value: "Smartlink",
                        ...trackingContext
                    });
                }}
            >
                <span>Best Offer</span>
            </Link>
        </div>
    );
};

export default SmartlinkRow;
