import React from "react";
import {PostCardProps} from "./PostCard.props";
import {ArrowShortLeft} from "@bettorsignals/assets";
import {useFramework} from "../internal/useFramework";

const PostCard: React.FC<PostCardProps> = (props) => {
    const {date, title, description, href, linkCta, coverImageUrl} = props;
    const {Link} = useFramework();

    return (
        <Link to={href} className="group flex flex-1 flex-col gap-3 md:flex-row">
            {coverImageUrl && (
                <img
                    className="h-[152px] w-full object-cover group-hover:saturate-150 md:w-[255px] md:min-w-[255px]"
                    loading="lazy"
                    src={coverImageUrl}
                    alt={`${title} cover image`}
                />
            )}

            <div>
                <span className="mb-2 text-sm font-normal text-gray-500">{date}</span>
                <div className="flex flex-col gap-y-3">
                    <h3 className="text-xl font-semibold text-gray-900">
                        <span>{title}</span>
                    </h3>
                    <p className="max-h-12 text-base font-normal text-gray-500 line-clamp-2">
                        {description}
                    </p>
                    <span className="flex items-center gap-x-1.5 text-base font-semibold text-bs-purple">
                        {linkCta}
                        <ArrowShortLeft className="h-4 w-5 rotate-180 text-bs-purple duration-100 ease-in group-hover:ml-1" />
                    </span>
                </div>
            </div>
        </Link>
    );
};

export default PostCard;
