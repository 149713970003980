import React, {useState} from "react";
import {CategoryMenuProps} from "../CategoryMenu.props";
import {ChevronDownArrow, CheckMark} from "@bettorsignals/assets";

const CategoryMenuMobile: React.FC<CategoryMenuProps> = ({
    categories,
    selectedCategory,
    setSelectedCategory
}) => {
    const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);

    return (
        <div className="block select-none lg:hidden">
            <div
                onClick={() => setDropdownOpen((currentValue) => !currentValue)}
                className="flex items-center justify-between rounded-md border border-gray-300 bg-white p-2 py-2.5 px-2"
            >
                <div className="flex w-full items-center justify-start gap-2">
                    <span className="w-5 text-gray-900">
                        {categories[selectedCategory].IconSolid}
                    </span>
                    <span className="text-sm font-semibold leading-5 text-gray-900">
                        {categories[selectedCategory].name}
                    </span>
                </div>
                <div className="flex h-5 w-5 cursor-pointer items-center justify-center">
                    <ChevronDownArrow className="h-1.5 w-2.5" />
                </div>
            </div>
            {isDropdownOpen && (
                <div className="relative">
                    <div className="absolute z-40 mt-2 w-full overflow-auto rounded-md border border-gray-300 bg-white py-1 shadow-sm">
                        <ul className="cursor-pointer select-none focus:outline-none">
                            {Object.values(categories).map((category) => (
                                <li
                                    key={category.id}
                                    onClick={() => {
                                        setSelectedCategory(category.id);
                                        setDropdownOpen(false);
                                    }}
                                    className="group relative flex items-center justify-start gap-3 py-2 px-3 leading-5"
                                >
                                    {selectedCategory === category.id ? (
                                        <>
                                            <span className="text-gray-900">
                                                <CheckMark className="h-5 w-5" />
                                            </span>
                                            <span className="text-sm font-semibold text-gray-900">
                                                {category.name}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="w-5 text-gray-600 group-hover:text-gray-900">
                                                {category.IconOutline}
                                            </span>
                                            <span className="text-sm font-normal text-gray-600 group-hover:font-semibold group-hover:text-gray-900">
                                                {category.name}
                                            </span>
                                        </>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CategoryMenuMobile;
