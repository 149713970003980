import * as React from "react";
import {SVGProps} from "react";
const SvgShare = (props: SVGProps<SVGSVGElement>) => (
    <svg
        viewBox="0 0 14 14"
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4.79 7.895C4.923 7.625 5 7.322 5 7c0-.322-.076-.625-.21-.895m0 1.79a2 2 0 1 1 0-1.79m0 1.79 4.42 2.21m-4.42-4 4.42-2.21m0 0a2 2 0 1 0 3.578-1.79 2 2 0 0 0-3.577 1.79Zm0 6.21a2 2 0 1 0 3.578 1.79 2 2 0 0 0-3.577-1.79Z"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default SvgShare;
