import {ArrowLongRight} from "@bettorsignals/assets";
import React from "react";
import {useFramework} from "../internal/useFramework";
import {PostPreviewProps} from "./PostPreview.props";

const PostPreview: React.FC<PostPreviewProps> = ({
    date,
    href,
    coverImageUrl,
    readingTimeInMinutes,
    title
}) => {
    const {Link} = useFramework();

    return (
        <Link to={href} className="group flex flex-col gap-2">
            <img
                className="h-24 w-full rounded object-cover"
                loading="lazy"
                src={coverImageUrl}
                alt={`${title} cover image`}
            />
            <div className="flex items-start justify-between gap-1">
                <p className="h-full text-sm font-bold group-hover:text-bs-purple">{title}</p>
                <ArrowLongRight className="h-5 w-6 self-baseline text-gray-900 group-hover:text-bs-purple" />
            </div>
            <div className="flex text-sm text-gray-600">
                {date}, {readingTimeInMinutes} min read
            </div>
        </Link>
    );
};

export default PostPreview;
