import React from "react";

/*
    Wrapper-component that renders gradient background below the rendered content.
 */
const WithBackgroundGradient: React.FC = ({children}) => (
    <>
        <div
            aria-hidden="true"
            className="-z-49 pointer-events-none fixed top-0 right-0 h-full w-full bg-bs-page-gradient-layer-1"
        />
        <div
            aria-hidden="true"
            className="pointer-events-none fixed top-0 right-0 -z-50 h-full w-full bg-bs-page-gradient-layer-2"
        />

        {children}
    </>
);

export default WithBackgroundGradient;
