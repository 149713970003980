import React from "react";
import {Refresh} from "@bettorsignals/assets";

const LoadingIndicator: React.FC = () => (
    <div
        className="flex w-fit rounded-[10px] bg-gray-200 py-0.5 px-2.5 text-gray-700"
        aria-label="Loading indicator"
        aria-busy
    >
        <Refresh className="chromatic-ignore h-4 w-4 animate-spin" />
        <span className="ml-1 text-sm font-medium leading-4">wait for a sec...</span>
    </div>
);

export default LoadingIndicator;
