import React from "react";
import {useFramework} from "../internal/useFramework";
import type {OfferBannerProps} from "./OfferBanner.props";
import {ArrowLongRight} from "@bettorsignals/assets";
import {useAnalytics} from "@bettorsignals/tracking";

const OfferBanner: React.FC<OfferBannerProps> = (props) => {
    const {id, name, terms, action, url, colors, logoRectangle} = props;
    const {Link} = useFramework();
    const {event} = useAnalytics();

    return (
        <Link
            to={url}
            className="group"
            onClick={(): void =>
                event({
                    action: "Offer Click",
                    category: "Offer Banner",
                    label: name,
                    value: id
                })
            }
        >
            <div className="border border-gray-200 bg-white">
                <div className="flex flex-row flex-wrap gap-3 px-3 py-6 md:items-center md:gap-6 md:py-8 md:px-6">
                    <div
                        className="flex h-16 items-center justify-center px-1 md:min-w-[166px] md:px-0"
                        style={{backgroundColor: colors.background}}
                    >
                        {logoRectangle && (
                            <img className="h-7" src={logoRectangle} alt={"Offer logo"} />
                        )}
                    </div>
                    <div className="flex-1">
                        <p className="text-center text-lg font-bold leading-7 text-gray-900 sm:text-2xl md:mb-[10px] md:text-3xl md:leading-9">
                            {name}
                        </p>
                    </div>

                    <div className="mt-3 flex w-full flex-col items-center gap-3 md:my-6 md:mx-3 md:w-auto md:min-w-[190px]">
                        <button className="flex w-full cursor-pointer items-center justify-center gap-2 rounded-md border border-bs-pink bg-bs-pink py-2 text-white shadow-sm transition-colors duration-100 ease-in-out group-hover:bg-white group-hover:text-bs-pink">
                            <span className="text-base font-medium leading-6">{action}</span>
                            <span className="h-5 w-5">
                                <ArrowLongRight />
                                <span className="sr-only">Arrow pointing right</span>
                            </span>
                        </button>
                        <p className="text-center text-xs font-normal leading-3 text-gray-800 md:text-[10px]">
                            Please Gamble Responsibly
                        </p>
                    </div>
                </div>
                <div className="border-t border-gray-200 bg-gray-50">
                    <p className="py-[10px] px-3 text-center text-xs font-normal leading-4 text-gray-600">
                        {terms}
                    </p>
                </div>
            </div>
        </Link>
    );
};

export default OfferBanner;
