import React, {Fragment, useCallback, useState} from "react";
import type {BetsGridProps} from "./BetsGrid.props";
import BetsGridCalculator from "./BetsGridCalculator";
import {BetsGridTableHeader} from "./BetsGridTableHeader";
import {BetsGridRow} from "./BetsGridRow";
import {SmartlinkRow} from "./BetsGridRow/SmartlinkRow";
import {SmartlinkFallback} from "./SmartlinkFallback";

const STAKES = [1, 3, 5, 10, 25, 50];

const BetsGrid: React.FC<BetsGridProps> = (props) => {
    const {bookmakers = [], odds, trackingContext} = props;
    const [stake, setStake] = useState<number>(STAKES[3]);

    const handleOnStakeSelect = useCallback((value: number) => setStake(value), []);

    return (
        <div className="flex justify-center">
            <div className="w-full max-w-3xl">
                {odds && (
                    <BetsGridCalculator
                        values={STAKES}
                        selectedStake={stake}
                        onSelect={handleOnStakeSelect}
                        bookmakers={bookmakers}
                        odds={odds}
                    />
                )}

                <div className="bg-gray-50">
                    <BetsGridTableHeader />

                    {bookmakers.length > 0 ? (
                        <Fragment>
                            <SmartlinkRow trackingContext={trackingContext} />
                            {bookmakers.map((bookmaker, index) => {
                                return (
                                    <Fragment key={index}>
                                        <BetsGridRow
                                            bookmaker={bookmaker}
                                            trackingContext={trackingContext}
                                        />
                                    </Fragment>
                                );
                            })}
                        </Fragment>
                    ) : (
                        <SmartlinkFallback trackingContext={trackingContext} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default BetsGrid;
