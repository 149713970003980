import React from "react";
import {BackButtonProps} from "./BackButton.props";
import {ArrowShortLeft} from "@bettorsignals/assets";

const BackButton: React.FC<BackButtonProps> = ({onClick, children}) => {
    return (
        <button
            onClick={onClick}
            className="flex h-[30px] w-[73px] items-center justify-between rounded bg-gradient-to-r from-bs-pink-light to-bs-purple-light px-[11px] py-2.5 text-xs font-medium capitalize text-white shadow-sm"
        >
            <ArrowShortLeft className="h-3 w-3.5" />
            {children}
        </button>
    );
};

export default BackButton;
