import React from "react";
import {StarProps} from "./Star.props";
import {Star as StarIcon} from "@bettorsignals/assets";

const Star: React.FC<StarProps> = ({starIndex, value}) => {
    if (starIndex + 1 <= value)
        return (
            <StarIcon
                className="h-3.5 w-3.5 fill-amber-300"
                aria-label="a golden star"
                role="img"
            />
        );

    if (starIndex >= value)
        return (
            <StarIcon className="h-3.5 w-3.5 fill-gray-300" aria-label="a grayed star" role="img" />
        );

    const starFraction = value - starIndex;
    const fractionPercentage = `${starFraction * 100}%`;

    return (
        <svg className="h-3.5 w-3.5" aria-label="a partially golden star" role="img">
            <defs>
                <linearGradient id="starFraction">
                    <stop offset={fractionPercentage} stopColor="#FCD34D" />
                    <stop offset={fractionPercentage} stopColor="#D1D5DB" />
                </linearGradient>
            </defs>
            <StarIcon fill="url('#starFraction')" />
        </svg>
    );
};

export default Star;
