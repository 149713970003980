import React from "react";
import clsx from "clsx";
import {BetsGridRowProps} from "./BetsGridRow.props";
import {useFramework} from "../../internal/useFramework";
import {useAnalytics} from "@bettorsignals/tracking";

const BetsGridRow: React.FC<BetsGridRowProps> = (props) => {
    const {bookmaker, trackingContext} = props;

    const {Link} = useFramework();
    const {event} = useAnalytics();

    return (
        <div className="group relative flex h-[60px] items-center justify-between overflow-hidden px-4">
            {/*Bookie Logo Column*/}
            <div
                className="flex h-[34px] w-10 justify-center overflow-hidden rounded"
                style={{backgroundColor: bookmaker.details.colors.background}}
            >
                <img
                    className="h-[34px] w-[34px]"
                    src={bookmaker.details.logoSquare}
                    alt={`${bookmaker.details.name} logo`}
                />
            </div>

            {/*Bet Buttons Column*/}
            <div>
                <Link
                    to={bookmaker.url}
                    onClick={(): void => {
                        event({
                            action: "Bet Button Click",
                            label: "Bet Now",
                            value: bookmaker.details.name,
                            ...trackingContext
                        });
                    }}
                    className={clsx(
                        "mx-auto flex h-[34px] w-[80px] items-center justify-center gap-2 whitespace-nowrap rounded-md",
                        "bg-gradient-to-r from-bs-pink via-bs-purple to-bs-pink bg-size-200 bg-position-0 py-[9px] px-[10.5px] text-xs font-medium leading-4 text-white",
                        "transition-all duration-500 ease-in-out hover:bg-position-100 md:text-sm"
                    )}
                >
                    <span>Bet Now</span>
                </Link>
            </div>
        </div>
    );
};

export default BetsGridRow;
