import * as React from "react";
import {SVGProps} from "react";
const SvgEsportOutline = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M20.85 17.084 18.978 11.4a2.888 2.888 0 0 0-2.748-1.999h-3.904c-.125-.5-.531-.905-1.03-.999a1.843 1.843 0 0 0-1.812-1.53h-3.56a.427.427 0 0 1-.437-.437c0-.25.187-.438.437-.438h9.244c.624 0 1.155-.53 1.155-1.155s-.53-1.156-1.155-1.156H12.7c-.75 0-1.374-.624-1.374-1.374 0-.187-.125-.312-.313-.312-.187 0-.312.125-.312.312 0 1.093.906 1.999 1.999 1.999h2.467c.281 0 .53.25.53.531s-.249.531-.53.531H5.923c-.594 0-1.062.468-1.062 1.062 0 .593.468 1.061 1.062 1.061h3.56c.562 0 1.062.406 1.186.937-.468.125-.843.5-.968.968H5.766c-1.249 0-2.373.812-2.748 2l-1.874 5.683C.24 19.801 3.768 23.05 6.86 18.895c.25-.343.5-.687.812-.999h6.621c.281.312.562.656.812 1 .718.967 1.468 1.998 2.967 1.998 1.998-.031 3.404-1.936 2.78-3.81Zm-9.837-8.12c.282 0 .531.156.656.406h-1.311a.728.728 0 0 1 .655-.406Zm7.09 11.274c-1.187 0-1.75-.78-2.467-1.75-.281-.374-.594-.78-.937-1.155a.338.338 0 0 0-.219-.093H7.547a.283.283 0 0 0-.218.093c-.375.375-.656.781-.937 1.156-2.655 3.591-5.31.812-4.622-1.25l1.874-5.683a2.279 2.279 0 0 1 2.155-1.562H16.26c1 0 1.843.625 2.155 1.562l1.874 5.684a2.292 2.292 0 0 1-2.186 2.998Z" />
        <path d="M11.014 12.43a1.186 1.186 0 1 0 1.186 1.188c-.031-.656-.53-1.187-1.186-1.187Zm0 1.718a.56.56 0 0 1-.563-.562.56.56 0 0 1 .563-.562.56.56 0 0 1 .562.562c-.032.313-.25.562-.563.562ZM6.797 13.305v-.687c0-.187-.125-.312-.312-.312s-.312.125-.312.312v.687h-.687c-.188 0-.313.125-.313.313 0 .187.125.312.313.312h.687v.687c0 .187.125.312.312.312s.312-.125.312-.312v-.687h.687c.188 0 .313-.125.313-.312 0-.188-.125-.313-.313-.313h-.687Z" />
        <path d="M6.485 11.338c-1.249 0-2.28 1.03-2.28 2.28 0 1.249 1.031 2.28 2.28 2.28 1.25 0 2.28-1.031 2.28-2.28 0-1.28-1-2.28-2.28-2.28Zm0 3.935c-.905 0-1.655-.75-1.655-1.655 0-.906.75-1.656 1.655-1.656.906 0 1.655.75 1.655 1.656 0 .905-.718 1.655-1.655 1.655ZM15.542 11.338c-1.25 0-2.28 1.03-2.28 2.28 0 1.249 1.03 2.28 2.28 2.28 1.249 0 2.28-1.031 2.28-2.28 0-1.28-1.031-2.28-2.28-2.28Zm0 3.935c-.906 0-1.655-.75-1.655-1.655 0-.906.75-1.656 1.655-1.656s1.655.75 1.655 1.656c0 .905-.75 1.655-1.655 1.655Z" />
        <path d="M15.292 12.68a.302.302 0 0 0 0 .438.302.302 0 0 0 .437 0 .302.302 0 0 0 0-.437c-.094-.125-.312-.125-.437 0ZM16.01 13.399a.302.302 0 0 0 0 .437.302.302 0 0 0 .438 0 .302.302 0 0 0 0-.437c-.094-.125-.313-.125-.438 0ZM14.605 13.399a.302.302 0 0 0 0 .437.302.302 0 0 0 .437 0 .302.302 0 0 0 0-.437c-.125-.125-.344-.125-.437 0ZM15.292 14.117a.302.302 0 0 0 0 .438.302.302 0 0 0 .437 0 .302.302 0 0 0 0-.438c-.094-.125-.312-.125-.437 0Z" />
    </svg>
);
export default SvgEsportOutline;
