import React, {useState} from "react";
import clsx from "clsx";
import {CheckMark} from "@bettorsignals/assets";
import {CopyButtonProps} from "./CopyButton.props";

const CopyButton: React.FC<CopyButtonProps> = ({onClick}) => {
    const [isCopied, setIsCopied] = useState(false);
    return (
        <button
            className={clsx(
                "h-5 w-[100px] rounded-[10px] py-0.5 px-2.5 text-xs font-medium text-white",
                isCopied ? "bg-green-800" : "bg-gray-600"
            )}
            onClick={() => {
                setIsCopied(true);
                onClick();
            }}
        >
            {isCopied ? (
                <div className="flex items-center justify-center gap-x-1.5">
                    <span>Copied</span>
                    <CheckMark className="h-2 w-3 fill-white" />
                </div>
            ) : (
                <span>Copy Link</span>
            )}
        </button>
    );
};

export default CopyButton;
