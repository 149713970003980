import * as React from "react";
import {SVGProps} from "react";
const SvgTelegramGradient = (props: SVGProps<SVGSVGElement>) => (
    <svg
        style={{
            width: 0,
            height: 0,
            position: "absolute"
        }}
        aria-hidden="true"
        {...props}
    >
        <defs>
            <linearGradient id="TelegramGradient_svg__telegramGradient">
                <stop offset="0%" stopColor="#4FA1DD" />
                <stop offset="100%" stopColor="#3C8BCB" />
            </linearGradient>
        </defs>
    </svg>
);
export default SvgTelegramGradient;
