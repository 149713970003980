import * as React from "react";
import {SVGProps} from "react";
const SvgArrowShortLeft = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 14 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.766 11.366a.8.8 0 0 1-1.132 0l-4.8-4.8a.8.8 0 0 1 0-1.132l4.8-4.8a.8.8 0 0 1 1.132 1.132L3.33 5.2h9.27a.8.8 0 0 1 0 1.6H3.33l3.435 3.434a.8.8 0 0 1 0 1.132Z"
        />
    </svg>
);
export default SvgArrowShortLeft;
