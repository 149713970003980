import React from "react";
import {BsGradientPrimary, BsGradientSecondary, TelegramGradient} from "@bettorsignals/assets";
import FrameworkProvider, {FrameworkAgnosticComponents} from "../internal/FrameworkProvider";
import ConfigProvider, {ConfigContextValue} from "../internal/ConfigProvider";

/**
 * Accepts framework-components such as Remix's Link to be used instead of regular anchor tag.
 * Also renders SVG gradients that are required to handle SVG gradient fills with CSS classes.
 */
const UIProvider: React.FC<FrameworkAgnosticComponents & ConfigContextValue> = (props) => {
    const {children, currency, oddsFormat, ...components} = props;

    return (
        <ConfigProvider currency={currency} oddsFormat={oddsFormat}>
            <FrameworkProvider {...components}>
                <BsGradientPrimary />
                <BsGradientSecondary />
                <TelegramGradient />
                {children}
            </FrameworkProvider>
        </ConfigProvider>
    );
};

export default UIProvider;
