import React from "react";
import {SocialMediaIcon} from "../SocialMediaIcon";
import {BannerProps} from "./Banner.props";

const Banner: React.FC<BannerProps> = ({contents, socialMediaIcons = []}) => {
    return (
        <>
            <div className="flex flex-col items-center justify-center text-center text-3xl font-extrabold md:text-4xl">
                {contents.map(({text, className}, index) => (
                    <h2 key={index} className={className ?? "text-gray-900"}>
                        {text}
                    </h2>
                ))}
            </div>

            {socialMediaIcons.length > 0 && (
                <div className="mt-9 flex items-center justify-center gap-6">
                    {socialMediaIcons.map((socialMediaIcon, index) => (
                        <SocialMediaIcon key={index} {...socialMediaIcon} />
                    ))}
                </div>
            )}
        </>
    );
};

export default Banner;
