import React from "react";
import {ArrowLongRight} from "@bettorsignals/assets";
import type {OfferTileProps} from "./OfferTile.props";
import {useFramework} from "../internal/useFramework";
import {useAnalytics} from "@bettorsignals/tracking";

const OfferTile: React.FC<OfferTileProps> = (props) => {
    const {id, sneakPeek, terms, action, logoRectangle, backgroundImage, url, colors} = props;
    const {Link} = useFramework();
    const {event} = useAnalytics();

    return (
        <Link
            to={url}
            className="group"
            onClick={(): void =>
                event({
                    action: "Offer Click",
                    category: "Offer Tile",
                    label: sneakPeek,
                    value: id
                })
            }
        >
            <div
                className="h-[242px] w-[255px] overflow-hidden bg-contain bg-right-bottom bg-no-repeat"
                style={{
                    backgroundColor: colors.background,
                    backgroundImage: backgroundImage ? `url("${backgroundImage}")` : "unset"
                }}
            >
                <div
                    className="flex h-full flex-col items-start p-4"
                    style={{color: colors.sneakPeek}}
                >
                    {logoRectangle && (
                        <img className="h-7" src={logoRectangle} alt={"Offer logo"} />
                    )}

                    <p className="flex flex-1 flex-wrap items-start whitespace-pre-line pt-6 text-base font-medium leading-6">
                        {sneakPeek}
                    </p>

                    <button className="my-2 flex w-full max-w-[180px] items-center justify-center gap-2 rounded-md border border-bs-pink bg-bs-pink py-2 text-white shadow-sm transition-colors duration-100 ease-in-out group-hover:bg-white group-hover:text-bs-pink">
                        <span className="text-sm font-medium leading-4">{action}</span>
                        <span className="h-4 w-4">
                            <ArrowLongRight />
                            <span className="sr-only">Arrow pointing right</span>
                        </span>
                    </button>

                    <p className="text-[8px] font-normal leading-3">{terms}</p>
                </div>
            </div>
        </Link>
    );
};

export default OfferTile;
