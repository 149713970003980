import * as React from "react";
import {SVGProps} from "react";
const SvgArrowLongRight = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="m17 8 4 4m0 0-4 4m4-4H3"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default SvgArrowLongRight;
