import React from "react";
import clsx from "clsx";
import {ArrowShortLeft} from "@bettorsignals/assets";
import {useFramework} from "../../internal/useFramework";
import type {PaginationArrowProps} from "./PaginationArrow.props";

const PaginationArrow: React.FC<PaginationArrowProps> = ({disabled, to, direction, label}) => {
    const {Link} = useFramework();

    /* We cannot render "Next" link with +1 page that is above the current pagesCount,
       because crawlers will see it as infinite number of pages on our website.
       That's why if disabled we are returning "#" as a href.
    */
    return (
        <Link
            to={disabled ? "#" : to}
            className={clsx(
                "-mt-1 inline-flex select-none items-center border-t-2 border-transparent px-1 pt-4 sm:-mt-px",
                "text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 disabled:opacity-70",
                disabled ? "pointer-events-none cursor-default" : "cursor-pointer"
            )}
        >
            {direction === "left" ? (
                <>
                    <ArrowShortLeft className={"mr-3 h-4 w-4 text-gray-400"} aria-hidden="true" />
                    <span className="hidden sm:flex">{label}</span>
                </>
            ) : (
                <>
                    <span className="hidden sm:flex">{label}</span>
                    <ArrowShortLeft
                        className={"ml-3 h-4 w-4 rotate-180 text-gray-400"}
                        aria-hidden="true"
                    />
                </>
            )}
        </Link>
    );
};

export default PaginationArrow;
