import React from "react";
import clsx from "clsx";
import {DefaultBookmaker} from "@bettorsignals/assets";
import type {SmartlinkFallbackProps} from "./SmartlinkFallback.props";
import {useAnalytics} from "@bettorsignals/tracking";
import {useFramework} from "../../internal/useFramework";

const SmartlinkFallback: React.FC<SmartlinkFallbackProps> = ({trackingContext}) => {
    const {Link} = useFramework();
    const {event} = useAnalytics();

    return (
        <div className="grid h-[60px] grid-cols-7 items-center gap-x-1 px-4">
            <div className="col-span-1">
                <div className="flex h-[34px] w-10 items-center justify-center rounded bg-gray-300">
                    <DefaultBookmaker className="h-4 w-4 text-gray-50" />
                </div>
            </div>

            <div className="col-span-6 w-full max-w-[142px] justify-self-end">
                <Link
                    to="https://maxbon.us/bettorsignals"
                    target="_blank"
                    rel="noreferrer"
                    className={clsx(
                        "flex h-[34px] w-full bg-size-200 bg-position-0 hover:bg-position-100",
                        "items-center justify-center gap-2 whitespace-nowrap rounded-md bg-gradient-to-r from-bs-pink via-bs-purple to-bs-pink py-[9px] px-[10.5px]",
                        "text-xs font-medium leading-4 text-white transition-all duration-500 ease-in-out lg:text-sm"
                    )}
                    onClick={(): void => {
                        event({
                            action: "Bet Button Click",
                            label: "Bet Now",
                            value: "Smartlink",
                            ...trackingContext
                        });
                    }}
                >
                    <span>Bet Now</span>
                </Link>
            </div>
        </div>
    );
};

export default SmartlinkFallback;
