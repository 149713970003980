import React from "react";
import clsx from "clsx";
import {ArrowShortLeft} from "@bettorsignals/assets";
import {useScrollOffset} from "./ScrollToTopButton.utils";
import {ScrollToTopButtonProps} from "./ScrollToTopButton.props";

const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({offset}) => {
    const {isPastOffset} = useScrollOffset(offset);
    return (
        <button
            aria-label="Scroll to top of the page"
            className={clsx(
                isPastOffset ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0",
                "fixed right-[18px] bottom-[18px] flex h-12 w-12 cursor-pointer items-center justify-center rounded bg-cyan-900 transition-opacity duration-200"
            )}
            onClick={() => scrollTo({top: 0, behavior: "smooth"})}
        >
            <ArrowShortLeft className="h-8 w-[25px] rotate-90 fill-white" />
        </button>
    );
};

export default ScrollToTopButton;
