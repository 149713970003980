import * as React from "react";
import {SVGProps} from "react";
const SvgBettorSignals = (props: SVGProps<SVGSVGElement>) => (
    <svg
        viewBox="0 0 488 168"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinejoin: "round",
            strokeMiterlimit: 2
        }}
        {...props}
    >
        <path
            style={{
                fill: "none"
            }}
            d="M0 0h1600v900H0z"
            transform="scale(.305 .18667)"
        />
        <path
            d="M158.019 278.857c-1.182 3.034-3.347 5.591-6.494 7.671-3.146 2.08-6.779 3.463-10.899 4.148 3.662.636 6.367 1.848 8.117 3.634 1.749 1.786 2.47 3.879 2.161 6.277-.101.783-.316 1.615-.645 2.496-1.737 4.551-5.302 8.063-10.695 10.534-5.393 2.472-12.228 3.708-20.506 3.708H79l20.185-51.976h38.978c6.91 0 12.173.918 15.788 2.753 3.614 1.835 5.195 4.515 4.742 8.039-.107.832-.332 1.737-.674 2.716Zm-25.672 3.157c.104-.245.181-.563.231-.955.126-.979-.232-1.688-1.076-2.129-.843-.44-2.2-.66-4.071-.66h-9.286l-2.888 7.341h9.285c2.16 0 3.889-.294 5.188-.881 1.3-.587 2.172-1.493 2.617-2.716Zm-5.638 18.646c.103-.244.18-.563.231-.954.239-1.86-1.477-2.79-5.148-2.79h-10.905l-2.78 7.341h10.905c2.159 0 3.869-.281 5.129-.844 1.26-.563 2.116-1.48 2.568-2.753Z"
            style={{
                fill: "url(#BettorSignals_svg__a)",
                fillRule: "nonzero"
            }}
            transform="matrix(.88911 0 0 1.3077 -64.303 -342.99)"
        />
        <path
            d="m191.998 278.27-2.451 6.46h23.754l-4.816 12.26h-23.754l-2.888 7.341h26.993l-5.019 12.994h-50.964l20.185-51.976h50.963l-5.01 12.921h-26.993Z"
            style={{
                fill: "url(#BettorSignals_svg__b)",
                fillRule: "nonzero"
            }}
            transform="matrix(.88911 0 0 1.3077 -64.303 -342.99)"
        />
        <path
            d="m293.752 265.349-5.01 12.921h-20.299l-15.175 39.055h-23.97l15.175-39.055H224.39l5.01-12.921h64.352Z"
            style={{
                fill: "url(#BettorSignals_svg__c)",
                fillRule: "nonzero"
            }}
            transform="matrix(.88911 0 0 1.3077 -63.303 -342.99)"
        />
        <path
            d="m363.286 265.349-5.01 12.921h-20.299l-15.174 39.055h-23.97l15.175-39.055h-20.083l5.009-12.921h64.352Z"
            style={{
                fill: "url(#BettorSignals_svg__d)",
                fillRule: "nonzero"
            }}
            transform="matrix(.88911 0 0 1.3077 -62.303 -342.99)"
        />
        <path
            d="M386.98 317.839c-6.478 0-12.048-.906-16.71-2.717-4.662-1.81-8.112-4.368-10.35-7.671-2.238-3.304-3.083-7.084-2.535-11.342.799-6.216 3.396-11.709 7.789-16.481 4.393-4.772 10.162-8.492 17.308-11.159 7.145-2.667 15.145-4.001 23.999-4.001 6.622 0 12.284.893 16.984 2.68 4.701 1.786 8.174 4.307 10.419 7.561 2.244 3.255 3.092 7.011 2.545 11.269-.794 6.166-3.392 11.672-7.794 16.518-4.403 4.845-10.215 8.613-17.435 11.305-7.221 2.692-15.294 4.038-24.22 4.038Zm5.627-15.197c3.743 0 7.087-.673 10.031-2.018 2.945-1.346 5.28-3.133 7.006-5.36 1.726-2.226 2.756-4.637 3.09-7.231.346-2.691-.446-4.784-2.378-6.276-1.931-1.493-4.876-2.239-8.835-2.239-3.743 0-7.103.66-10.081 1.982-2.977 1.321-5.347 3.095-7.109 5.322-1.762 2.227-2.81 4.637-3.144 7.231-.346 2.692.445 4.796 2.373 6.314 1.928 1.517 4.944 2.275 9.047 2.275Z"
            style={{
                fill: "url(#BettorSignals_svg__e)",
                fillRule: "nonzero"
            }}
            transform="matrix(.88911 0 0 1.3077 -62.303 -342.99)"
        />
        <path
            d="M490.478 265.349c8.206 0 14.272 1.248 18.198 3.744 3.925 2.496 5.621 5.824 5.085 9.984-.157 1.224-.443 2.325-.857 3.304-1.389 3.523-4.019 6.619-7.889 9.286-3.87 2.668-8.688 4.589-14.454 5.763l8.885 19.895h-26.345l-7.211-18.647-7.258 18.647h-23.97l20.185-51.976h35.631Zm-20.382 22.464h9.501c2.304 0 4.167-.355 5.59-1.064 1.423-.71 2.448-1.823 3.075-3.341.104-.244.181-.562.231-.954.139-1.077-.257-1.921-1.186-2.533-.929-.611-2.365-.917-4.309-.917h-9.501l-3.401 8.809Z"
            style={{
                fill: "url(#BettorSignals_svg__f)",
                fillRule: "nonzero"
            }}
            transform="matrix(.88911 0 0 1.3077 -62.303 -342.99)"
        />
        <path
            d="M111.758 391.25c-9.358 0-16.662-1.272-21.914-3.817-5.251-2.545-7.561-6.265-6.932-11.159.063-.489.23-1.223.5-2.202h25.481c-.411 1.517-.214 2.643.592 3.377.805.734 2.251 1.101 4.339 1.101 1.295 0 2.403-.22 3.324-.661.92-.44 1.434-1.076 1.541-1.908.151-1.175-.568-2.154-2.159-2.937-1.591-.783-4.189-1.713-7.793-2.789-3.952-1.175-7.172-2.301-9.661-3.377-2.489-1.077-4.585-2.545-6.29-4.405-1.704-1.86-2.377-4.185-2.018-6.974.473-3.671 2.23-6.84 5.273-9.507 3.042-2.667 7.063-4.686 12.062-6.056 4.999-1.371 10.594-2.056 16.785-2.056 5.542 0 10.415.587 14.619 1.762 4.204 1.174 7.404 2.875 9.601 5.102 2.197 2.227 3.1 4.857 2.71 7.892-.051.391-.211 1.076-.481 2.055h-25.805c.219-.587.342-.979.367-1.174.119-.93-.184-1.652-.909-2.166-.726-.514-1.773-.771-3.14-.771-1.224 0-2.263.245-3.118.734-.855.49-1.333 1.126-1.433 1.909-.139 1.077.568 2.019 2.119 2.826 1.552.808 4.052 1.799 7.5 2.974 3.933 1.321 7.173 2.569 9.721 3.744 2.549 1.174 4.67 2.728 6.365 4.661 1.695 1.933 2.363 4.295 2.004 7.084-.41 3.182-2.02 6.045-4.831 8.59-2.811 2.545-6.648 4.539-11.513 5.983-4.865 1.443-10.5 2.165-16.906 2.165Z"
            style={{
                fill: "url(#BettorSignals_svg__g)",
                fillRule: "nonzero"
            }}
            transform="matrix(.88911 0 0 1.3077 -15.303 -346.99)"
        />
        <path
            d="m192.473 338.761-20.185 51.975h-23.969l20.184-51.975h23.97Z"
            style={{
                fill: "url(#BettorSignals_svg__h)",
                fillRule: "nonzero"
            }}
            transform="matrix(.88911 0 0 1.3077 -15.303 -346.99)"
        />
        <path
            d="M191.082 364.675c1.957-5.139 5.281-9.715 9.972-13.728 4.692-4.013 10.384-7.133 17.077-9.36 6.693-2.227 13.962-3.34 21.808-3.34 6.695 0 12.303.746 16.826 2.239 4.523 1.493 7.777 3.61 9.764 6.35 1.987 2.741 2.633 5.971 1.938 9.691h-25.265c-.516-1.028-1.529-1.823-3.04-2.386-1.512-.563-3.491-.845-5.938-.845-4.607 0-8.572 1.028-11.896 3.084-3.324 2.055-5.641 4.82-6.952 8.295-.549 1.469-.883 2.668-1.003 3.597-.73 5.678 3.763 8.516 13.481 8.516 5.542 0 10.596-1.517 15.162-4.551h-19.111l4.398-11.526h38.978l-6.308 16.297c-4.816 3.867-10.678 7.183-17.584 9.948-6.906 2.765-14.822 4.148-23.748 4.148-6.91 0-12.702-.857-17.377-2.57-4.674-1.713-8.089-4.123-10.244-7.231-2.156-3.108-2.966-6.742-2.431-10.902.252-1.957.75-3.866 1.493-5.726Z"
            style={{
                fill: "url(#BettorSignals_svg__i)",
                fillRule: "nonzero"
            }}
            transform="matrix(.88911 0 0 1.3077 -15.303 -346.99)"
        />
        <path
            d="M337.595 390.736h-23.97l-15.209-26.134-10.165 26.134h-23.97l20.185-51.975h23.97l15.044 26.575 10.33-26.575h23.969l-20.184 51.975Z"
            style={{
                fill: "url(#BettorSignals_svg__j)",
                fillRule: "nonzero"
            }}
            transform="matrix(.88911 0 0 1.3077 -15.303 -346.99)"
        />
        <path
            d="M400.474 382.881h-25.482l-6.841 7.855h-25.158l48.15-51.975h27.641l7.672 51.975h-25.265l-.717-7.855Zm-1.014-12.259-1.27-14.462-12.55 14.462h13.82Z"
            style={{
                fill: "url(#BettorSignals_svg__k)",
                fillRule: "nonzero"
            }}
            transform="matrix(.88911 0 0 1.3077 -15.303 -346.99)"
        />
        <path
            d="M460.651 378.403h23.106l-4.826 12.333h-47.076l20.185-51.975h23.97l-15.359 39.642Z"
            style={{
                fill: "url(#BettorSignals_svg__l)",
                fillRule: "nonzero"
            }}
            transform="matrix(.88911 0 0 1.3077 -15.303 -346.99)"
        />
        <path
            d="M519.247 391.25c-9.358 0-16.662-1.272-21.913-3.817-5.251-2.545-7.562-6.265-6.932-11.159.063-.489.229-1.223.499-2.202h25.482c-.412 1.517-.214 2.643.591 3.377.805.734 2.252 1.101 4.339 1.101 1.296 0 2.404-.22 3.324-.661.921-.44 1.434-1.076 1.542-1.908.151-1.175-.569-2.154-2.16-2.937-1.591-.783-4.188-1.713-7.793-2.789-3.952-1.175-7.172-2.301-9.661-3.377-2.489-1.077-4.585-2.545-6.289-4.405-1.705-1.86-2.377-4.185-2.018-6.974.472-3.671 2.23-6.84 5.272-9.507 3.043-2.667 7.063-4.686 12.063-6.056 4.999-1.371 10.593-2.056 16.784-2.056 5.542 0 10.416.587 14.619 1.762 4.204 1.174 7.404 2.875 9.601 5.102 2.197 2.227 3.1 4.857 2.71 7.892-.051.391-.211 1.076-.481 2.055h-25.805c.219-.587.342-.979.367-1.174.12-.93-.183-1.652-.909-2.166s-1.772-.771-3.14-.771c-1.224 0-2.263.245-3.118.734-.854.49-1.332 1.126-1.433 1.909-.139 1.077.568 2.019 2.12 2.826 1.551.808 4.051 1.799 7.499 2.974 3.933 1.321 7.174 2.569 9.722 3.744 2.548 1.174 4.669 2.728 6.364 4.661 1.695 1.933 2.363 4.295 2.004 7.084-.409 3.182-2.02 6.045-4.83 8.59-2.811 2.545-6.649 4.539-11.514 5.983-4.864 1.443-10.5 2.165-16.906 2.165Z"
            style={{
                fill: "url(#BettorSignals_svg__m)",
                fillRule: "nonzero"
            }}
            transform="matrix(.88911 0 0 1.3077 -15.303 -346.99)"
        />
        <defs>
            <linearGradient
                id="BettorSignals_svg__a"
                x1={0}
                y1={0}
                x2={1}
                y2={0}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(535.506 0 0 123.723 79 326.33)"
            >
                <stop
                    offset="0%"
                    style={{
                        stopColor: "#db2777",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(219,39,119);rgb(89,25,163);rgb(89,25,163);rgb(219,39,119);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
                <stop
                    offset="100%"
                    style={{
                        stopColor: "#5919a3",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(89,25,163);rgb(217,39,119);rgb(89,25,163);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
            </linearGradient>
            <linearGradient
                id="BettorSignals_svg__b"
                x1={0}
                y1={0}
                x2={1}
                y2={0}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(535.506 0 0 123.723 79 326.33)"
            >
                <stop
                    offset="0%"
                    style={{
                        stopColor: "#db2777",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(219,39,119);rgb(89,25,163);rgb(89,25,163);rgb(219,39,119);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
                <stop
                    offset="100%"
                    style={{
                        stopColor: "#5919a3",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(217,39,119);rgb(89,25,163);rgb(219,39,119);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
            </linearGradient>
            <linearGradient
                id="BettorSignals_svg__c"
                x1={0}
                y1={0}
                x2={1}
                y2={0}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(535.506 0 0 123.723 79 326.33)"
            >
                <stop
                    offset={0}
                    style={{
                        stopColor: "#db2777",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(219,39,119);rgb(89,25,163);rgb(89,25,163);rgb(219,39,119);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
                <stop
                    offset={1}
                    style={{
                        stopColor: "#5919a3",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(217,39,119);rgb(89,25,163);rgb(219,39,119);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
            </linearGradient>
            <linearGradient
                id="BettorSignals_svg__d"
                x1={0}
                y1={0}
                x2={1}
                y2={0}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(535.506 0 0 123.723 79 326.33)"
            >
                <stop
                    offset={0}
                    style={{
                        stopColor: "#db2777",
                        stopOpacity: 1
                    }}
                />
                <stop
                    offset={1}
                    style={{
                        stopColor: "#5919a3",
                        stopOpacity: 1
                    }}
                />
            </linearGradient>
            <linearGradient
                id="BettorSignals_svg__e"
                x1={0}
                y1={0}
                x2={1}
                y2={0}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(535.506 0 0 123.723 79 326.33)"
            >
                <stop
                    offset="0%"
                    style={{
                        stopColor: "#db2777",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(89,25,163);rgb(219,39,119);rgb(219,39,119);rgb(89,25,163);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
                <stop
                    offset="100%"
                    style={{
                        stopColor: "#5919a3",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(89,25,163);rgb(219,39,119);rgb(89,25,163);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
            </linearGradient>
            <linearGradient
                id="BettorSignals_svg__f"
                x1={0}
                y1={0}
                x2={1}
                y2={0}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(535.506 0 0 123.723 79 326.33)"
            >
                <stop
                    offset="0%"
                    style={{
                        stopColor: "#db2777",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(89,25,163);rgb(219,39,119);rgb(219,39,119);rgb(89,25,163);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
                <stop
                    offset="100%"
                    style={{
                        stopColor: "#5919a3",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(89,25,163);rgb(219,39,119);rgb(89,25,163);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
            </linearGradient>
            <linearGradient
                id="BettorSignals_svg__g"
                x1={0}
                y1={0}
                x2={1}
                y2={0}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(535.506 0 0 123.723 79 326.33)"
            >
                <stop
                    offset={0}
                    style={{
                        stopColor: "#db2777",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(219,39,119);rgb(89,25,163);rgb(89,25,163);rgb(219,39,119);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
                <stop
                    offset={1}
                    style={{
                        stopColor: "#5919a3",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(219,39,119);rgb(89,25,163);rgb(219,39,119);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
            </linearGradient>
            <linearGradient
                id="BettorSignals_svg__h"
                x1={0}
                y1={0}
                x2={1}
                y2={0}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(535.506 0 0 123.723 79 326.33)"
            >
                <stop
                    offset={0}
                    style={{
                        stopColor: "#db2777",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(219,39,119);rgb(89,25,163);rgb(89,25,163);rgb(219,39,119);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
                <stop
                    offset={1}
                    style={{
                        stopColor: "#5919a3",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(219,39,119);rgb(89,25,163);rgb(219,39,119);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
            </linearGradient>
            <linearGradient
                id="BettorSignals_svg__i"
                x1={0}
                y1={0}
                x2={1}
                y2={0}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(535.506 0 0 123.723 79 326.33)"
            >
                <stop
                    offset={0}
                    style={{
                        stopColor: "#db2777",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(219,39,119);rgb(89,25,163);rgb(89,25,163);rgb(219,39,119);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
                <stop
                    offset={1}
                    style={{
                        stopColor: "#5919a3",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(219,39,119);rgb(89,25,163);rgb(219,39,119);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
            </linearGradient>
            <linearGradient
                id="BettorSignals_svg__j"
                x1={0}
                y1={0}
                x2={1}
                y2={0}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(535.506 0 0 123.723 79 326.33)"
            >
                <stop
                    offset={0}
                    style={{
                        stopColor: "#db2777",
                        stopOpacity: 1
                    }}
                />
                <stop
                    offset={1}
                    style={{
                        stopColor: "#5919a3",
                        stopOpacity: 1
                    }}
                />
            </linearGradient>
            <linearGradient
                id="BettorSignals_svg__k"
                x1={0}
                y1={0}
                x2={1}
                y2={0}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(535.506 0 0 123.723 79 326.33)"
            >
                <stop
                    offset="0%"
                    style={{
                        stopColor: "#db2777",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(89,25,163);rgb(219,39,119);rgb(219,39,119);rgb(89,25,163);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
                <stop
                    offset="100%"
                    style={{
                        stopColor: "#5919a3",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(89,25,163);rgb(219,39,119);rgb(89,25,163);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
            </linearGradient>
            <linearGradient
                id="BettorSignals_svg__l"
                x1={0}
                y1={0}
                x2={1}
                y2={0}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(535.506 0 0 123.723 79 326.33)"
            >
                <stop
                    offset="0%"
                    style={{
                        stopColor: "#db2777",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(89,25,163);rgb(219,39,119);rgb(219,39,119);rgb(89,25,163);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
                <stop
                    offset="100%"
                    style={{
                        stopColor: "#5919a3",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(89,25,163);rgb(219,39,119);rgb(89,25,163);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
            </linearGradient>
            <linearGradient
                id="BettorSignals_svg__m"
                x1={0}
                y1={0}
                x2={1}
                y2={0}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(535.506 0 0 123.723 79 326.33)"
            >
                <stop
                    offset="0%"
                    style={{
                        stopColor: "#db2777",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(89,25,163);rgb(219,39,119);rgb(219,39,119);rgb(89,25,163);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
                <stop
                    offset="100%"
                    style={{
                        stopColor: "#5919a3",
                        stopOpacity: 1
                    }}
                >
                    <animate
                        attributeName="stop-color"
                        values="rgb(89,25,163);rgb(219,39,119);rgb(89,25,163);"
                        dur="3.5s"
                        repeatCount="indefinite"
                    />
                </stop>
            </linearGradient>
        </defs>
    </svg>
);
export default SvgBettorSignals;
