import React, {useCallback, useRef, useState} from "react";
/* eslint-disable import/no-unresolved */ //https://github.com/eslint/eslint/issues/15171
import {Autoplay} from "swiper";
import {Swiper, SwiperSlide, SwiperRef} from "swiper/react";
import clsx from "clsx";
import type {NewsWidgetProps} from "./NewsWidget.props";

import {NewsItem} from "./NewsItem";

const NewsWidget: React.FC<NewsWidgetProps> = ({news}) => {
    const swiperRef = useRef<SwiperRef>(null);
    const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
    const [containerWidth, setContainerWidth] = useState<number>(0);

    //Swiper has an issue with the percentage values for the slide dimensions.
    //For the component to remain responsive, at the time of rendering,
    // a container width of slider is stored in state, which is than used to determine the width of the single slide.
    const handleContainerWidth = useCallback((node: HTMLDivElement | null) => {
        if (!node) return;
        setContainerWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div className="flex flex-col items-center">
            {/* Slider */}
            <div ref={handleContainerWidth} className="w-full overflow-hidden md:max-w-[540px]">
                <Swiper
                    ref={swiperRef}
                    modules={[Autoplay]}
                    autoHeight={true}
                    longSwipesRatio={0.2}
                    threshold={3}
                    autoplay={{delay: 5000, pauseOnMouseEnter: true, disableOnInteraction: false}}
                    onSlideChange={(swiper) => setCurrentSlideIndex(swiper.activeIndex)}
                >
                    {news.map((newsDetails) => (
                        <SwiperSlide key={newsDetails.title}>
                            <NewsItem {...newsDetails} containerWidth={containerWidth} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {/* Pagination Dots */}
            <div className="mt-2.5 flex w-full items-center justify-center gap-[3px]">
                {news.length > 1 &&
                    Array.from({
                        length: news.length
                    }).map((_, index) => (
                        <span
                            key={index}
                            className={clsx(
                                "cursor-pointer rounded-full",
                                currentSlideIndex === index
                                    ? "h-2 w-2 bg-bs-purple"
                                    : "h-1.5 w-1.5 bg-gray-400"
                            )}
                            onClick={() => swiperRef.current?.swiper.slideTo(index, 1, false)}
                        />
                    ))}
            </div>
        </div>
    );
};

export default NewsWidget;
