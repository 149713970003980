import * as React from "react";
import {SVGProps} from "react";
const SvgBsGradientSecondary = (props: SVGProps<SVGSVGElement>) => (
    <svg
        style={{
            width: 0,
            height: 0,
            position: "absolute"
        }}
        aria-hidden="true"
        {...props}
    >
        <defs>
            <linearGradient id="BsGradientSecondary_svg__bsGradientSecondary">
                <stop offset="0%" stopColor="#FB9DC8" />
                <stop offset="100%" stopColor="#9747FF" />
            </linearGradient>
        </defs>
    </svg>
);
export default SvgBsGradientSecondary;
