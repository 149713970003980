import React from "react";
import clsx from "clsx";
import {useFramework} from "../../internal/useFramework";
import {PaginationPageNumberProps} from "./PaginationPageNumber.props";

const PaginationPageNumber: React.FC<PaginationPageNumberProps> = ({
    children,
    to,
    isCurrentPage
}) => {
    const {Link} = useFramework();

    return (
        <Link
            to={to}
            className={clsx(
                "inline-flex w-10 select-none items-center justify-center  border-t-2 px-4 pt-4 text-sm font-medium",
                isCurrentPage
                    ? "pointer-events-none cursor-default border-bs-purple text-bs-purple"
                    : "cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
            )}
        >
            {children}
        </Link>
    );
};

export default PaginationPageNumber;
