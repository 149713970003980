import * as React from "react";
import {SVGProps} from "react";
const SvgBsGradientPrimary = (props: SVGProps<SVGSVGElement>) => (
    <svg
        style={{
            width: 0,
            height: 0,
            position: "absolute"
        }}
        aria-hidden="true"
        {...props}
    >
        <defs>
            <linearGradient id="BsGradientPrimary_svg__bsGradientPrimary">
                <stop offset="0%" stopColor="#F72585" />
                <stop offset="100%" stopColor="#7209B7" />
            </linearGradient>
        </defs>
    </svg>
);
export default SvgBsGradientPrimary;
