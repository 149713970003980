import {useCallback} from "react";

/**
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events
 * Any custom property can be passed in the event payload.
 */
export type TrackingEventPayload = {
    action: string; //The value that will appear as the event action in Google Analytics Event reports.
    category?: string; //The category of the event.
    label?: string; //The label of the event.
    value?: string | number; //A non-negative integer that will appear as the event value -> but also works with strings in practice.
    isNonInteraction?: boolean;
} & Record<string, any>;

declare global {
    interface Window {
        gtag: (option: string, action: string, options: Record<string, unknown>) => void;
    }
}

export const useAnalytics = () => {
    const pageView = useCallback((page: string) => {
        if (!window.gtag) return;

        window.gtag("event", "page_view", {
            page_path: page
        });
    }, []);

    const event = useCallback((payload: TrackingEventPayload) => {
        if (!window.gtag) return;

        const {
            action,
            category = "general",
            label,
            value,
            isNonInteraction = false,
            ...customParameters
        } = payload;

        const parameters = {
            event_category: category,
            event_label: label,
            value,
            ...(isNonInteraction ? {non_interaction: true} : {}),
            ...customParameters
        };

        window.gtag("event", action, parameters);
    }, []);

    return {pageView, event};
};
