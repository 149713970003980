import React, {Fragment} from "react";
import {RatingProps} from "./Rating.props";
import Star from "./Star";

const MAX_VALUE = 5;

const Rating: React.FC<RatingProps> = (props) => {
    const {value = 0} = props;

    return (
        <div className="flex gap-0.5" title={`${value}/${MAX_VALUE}`}>
            {Array.from({length: MAX_VALUE}).map((_, index) => (
                <Fragment key={index}>
                    <Star starIndex={index} value={value} />
                </Fragment>
            ))}
            <span className="sr-only">
                Rating: {value} out of {MAX_VALUE}
            </span>
        </div>
    );
};

export default Rating;
