import React, {useCallback, useState} from "react";
import clsx from "clsx";
import {Hamburger, BettorSignals} from "@bettorsignals/assets";
import {useFramework} from "../internal/useFramework";
import {NavBarProps} from "./NavBar.props";
import {useHeadroom} from "./NavBar.utils";
import MobileMenu from "./MobileMenu/MobileMenu";
import MenuItem from "./MenuItem/MenuItem";
import {SocialMediaIcon} from "../SocialMediaIcon";

const NavBar: React.FC<NavBarProps> = ({menuItems, contents, socialMediaIcons = []}) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
    const [navBarHeight, setNavBarHeight] = useState<number>(0);
    const {Link} = useFramework();

    const handleNavBarHeight = useCallback((node: HTMLDivElement | null) => {
        if (!node) return;
        setNavBarHeight(node.getBoundingClientRect().height);
    }, []);

    const {isPinned} = useHeadroom({threshold: navBarHeight});

    return (
        <nav
            ref={handleNavBarHeight}
            className={clsx(
                isPinned || isMobileMenuOpen ? "translate-y-0" : "-translate-y-full",
                "fixed inset-x-0 top-0 z-50 w-screen bg-white transition duration-500 md:h-16 md:transform-none"
            )}
        >
            <div className="mx-auto grid h-20 max-w-bs grid-cols-2 grid-rows-1 items-center border-gray-100 px-4 py-2 md:h-16 md:grid-cols-12 md:justify-start xl:px-0">
                <div className="md:col-span-3">
                    <div className="h-[30px] w-[90px]">
                        <Link to={"/"}>
                            <BettorSignals />
                        </Link>
                    </div>
                </div>
                <div className="-my-2 -mr-2 justify-self-end md:hidden">
                    <button
                        className="inline-flex items-center justify-center p-2"
                        onClick={() => setIsMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open menu</span>
                        <Hamburger className="h-4 w-5 text-bs-purple" />
                    </button>
                </div>
                <div className="col-span-7 hidden gap-x-8 md:flex">
                    {menuItems.map((menuItem, index) => (
                        <MenuItem key={index} {...menuItem} />
                    ))}
                </div>
                {socialMediaIcons.length > 0 && (
                    <div className="col-span-2 hidden items-center justify-end gap-x-8 md:grid md:grid-cols-4">
                        {socialMediaIcons.map((socialMediaIcon, index) => (
                            <SocialMediaIcon key={index} {...socialMediaIcon} />
                        ))}
                    </div>
                )}
            </div>
            <MobileMenu
                menuItems={menuItems}
                contents={contents}
                socialMediaIcons={socialMediaIcons}
                setClose={(): void => setIsMobileMenuOpen(false)}
                isOpen={isMobileMenuOpen}
            />
        </nav>
    );
};

export default NavBar;
