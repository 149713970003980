import React from "react";
import clsx from "clsx";
import {CategoryMenuProps} from "./CategoryMenu.props";

const CategoryMenu: React.FC<CategoryMenuProps> = ({
    categories,
    selectedCategory,
    setSelectedCategory
}) => (
    <div className="hidden max-w-[255px] lg:block">
        <ul className="w-full cursor-pointer select-none focus:outline-none">
            {Object.values(categories).map((category) => (
                <li
                    key={category.id}
                    onClick={() => setSelectedCategory(category.id)}
                    className={clsx(
                        "group relative flex items-center justify-between whitespace-nowrap py-3.5 pr-5 pl-3",
                        selectedCategory === category.id && "bg-gray-100"
                    )}
                >
                    <div className="flex w-full items-center justify-start gap-4">
                        {selectedCategory === category.id ? (
                            <>
                                <span className="w-5 text-gray-900">{category.IconSolid}</span>
                                <span className="text-sm font-semibold leading-5 text-gray-900">
                                    {category.name}
                                </span>
                            </>
                        ) : (
                            <>
                                <span className="w-5 text-gray-600 group-hover:text-gray-900">
                                    {category.IconOutline}
                                </span>
                                <span className="text-sm font-normal leading-5 text-gray-600 group-hover:font-semibold group-hover:text-gray-900">
                                    {category.name}
                                </span>
                            </>
                        )}
                    </div>
                    {category.count && (
                        <span className="ml-1 text-xs font-medium leading-4 tracking-wider text-gray-500">
                            +{category.count}
                        </span>
                    )}
                </li>
            ))}
        </ul>
    </div>
);

export default CategoryMenu;
