import React from "react";
import {MenuItemProps} from "../NavBar.props";
import {useFramework} from "../../internal/useFramework";

const MenuItem: React.FC<MenuItemProps & {onClick?: () => void}> = ({
    name,
    href,
    onClick = (): void => {},
    withLabel
}) => {
    const {Link} = useFramework();

    return (
        <div className="flex w-fit items-center gap-x-4 md:gap-x-2">
            <Link
                to={href}
                onClick={onClick}
                className="text-base font-semibold capitalize text-gray-900 hover:text-bs-gradient-primary md:font-medium"
            >
                {name}
            </Link>

            {withLabel && (
                <div className="flex h-5 w-[50px] items-center justify-center rounded-lg bg-gradient-to-r from-bs-pink-light to-bs-purple-light text-xs font-medium text-white">
                    New
                </div>
            )}
        </div>
    );
};

export default MenuItem;
